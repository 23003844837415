.sideNavigation {
  --icon-filter: invert(48%) sepia(24%) saturate(5361%) hue-rotate(194deg)
    brightness(105%) contrast(85%);
  width: 8rem;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  border-right: 0.1rem solid #e9ecef;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition: all 0.3s ease;
  z-index: 4 !important;

  &__logo {
    cursor: pointer;
    height: 4.9rem;
    width: 5.7rem;
    top: 32px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .notifications__navItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 1rem;
    width: 100%;
    padding-left: 3rem;
    height: 3rem;

    .sideNavigation__notificationIcon__badgeAndBell {
      padding-top: 0;
    }

    .title {
      margin-bottom: 0;
      padding-top: 0.6rem;
    }
  }

  .home__navItem {
    .title {
      padding-top: 0.4rem;
    }
  }

  .logout__navItem {
    height: 3rem;
    padding-left: 2rem;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    border-top: 0.4px solid #e9ecef;
    justify-content: start;
  }

  .logout__link__desktop {
    overflow: hidden;
    padding: 1rem;
  }

  .logout__link__mobile {
    display: none;
  }

  .user__navItem {
    filter: none;

    .title {
      max-height: 100%;
    }

    img {
      border-radius: 50%;
      height: 2.4rem;
      width: 2.5rem;
    }
  }

  &__navItem {
    display: flex;
    align-items: center;
    color: #adb5bd;
    gap: 1rem;
    height: 4rem;
    width: 100%;
    padding-left: 3rem;
    overflow: hidden;

    .title {
      font-size: 1.4rem;
      color: #495057;
      margin-bottom: 0;
      user-select: none;
      word-break: break-all;
    }

    svg {
      font-size: 2.5rem;
    }

    &__selected {
      color: @main-blue !important;
    }
  }

  .dashboard__navItem--mobile {
    display: none;
  }

  &__navItem:active,
  &__navItem:hover {
    filter: var(--icon-filter);
    p {
      color: @main-blue;
    }
  }

  .user__navItem:hover {
    filter: none !important;
  }

  .user__navItem--active,
  .user__navItem:hover {
    img {
      border: 1px solid @main-blue;
    }
  }

  .notifications__navItem:hover,
  .notifications__navItem--active {
    .sidebarIcon,
    .title {
      user-select: none;
      color: @main-blue;
      filter: var(--icon-filter);
    }
  }

  &__navItem--active {
    filter: var(--icon-filter);
    color: @main-blue;

    p {
      color: @main-blue;
    }
  }

  &__iconsWrapper {
    display: flex;
    gap: 3rem;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    max-height: calc(100vh - 25rem);
    position: relative;
    overflow: visible;
  }
  .open-button {
    background: white;
    position: absolute;
    display: block;
    top: 50%;
    right: -1.3rem;
    border: 0.1rem solid #fff;
    border-radius: 0.8rem;
    padding: 1.2rem 0.6rem;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 3;
  }

  &__notificationIcon {
    &__badgeAndBell {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1.1rem;
    }

    &__bellIcon {
      width: 2.5rem;
      height: 2.5rem;
      color: #adb5bd;
    }
  }
}

.sideNavigation {
  width: 8rem;
  &__navItem .title,
  .notifications__navItem .title {
    display: none;
  }

  .logout__navItem {
    width: 100%;
  }
  &--opened {
    width: 22rem;

    .logout__navItem {
      width: 20rem;
    }
    .notifications__navItem .title {
      display: block;
    }

    .sideNavigation {
      width: 22rem;

      &__navItem {
        width: 20rem;
      }
      &__navItem .title {
        display: block;
      }
    }
  }
}

.sidebarIcon {
  &__profile {
    font-size: 2rem;
  }
}

.notifications {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 100%;
  overflow-y: auto;
  height: 100vh;
  max-height: 100vh;
  width: 39rem;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  border-radius: 0px 8px 8px 0px;

  .ant-empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
  }

  &__header {
    font-weight: 600;
    font-size: 2rem;
    line-height: 3.2rem;
    color: #212529;
    display: flex;
    width: 100%;
    padding: 1.6rem 1.5rem;
    border-bottom: 1px solid #f1f3f5;
    justify-content: space-between;
    align-items: center;

    .ant-btn-default {
      border: none;
      color: @main-blue;
      padding: 0 0.4rem;
      background-color: transparent;
      box-shadow: none;
    }

    &__title {
      margin-bottom: 0;
    }
  }

  &__notification {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1.8rem 2.8rem 1.8rem;
    border-bottom: 1px solid #f1f3f5;

    &__text {
      margin: 0;
      word-break: break-word;
      font-size: 1.2rem;
      line-height: 2rem;

      color: #343a40;
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.8rem;
    }
    &__date-and-time {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;

      color: #495057;
    }
    &__separator {
      width: 0.5rem;
      height: 0.5rem;
      display: inline-block;
      margin-left: 0.4rem;
      margin-right: 0.4rem;
      background: #adb5bd;
      border-radius: 50%;
    }
    &__status {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 2rem;
      color: #ff4d4f;
    }
  }
  &__notification:hover {
    cursor: pointer;
  }
}
@media (max-width: @screen-lg) {
  .mainLayout {
    padding-left: 0 !important;
    overflow-x: hidden;
  }

  .sideNavigation {
    flex-direction: row;
    height: @footer-height;
    width: 100% !important;
    position: fixed;
    padding: 0 3rem;
    bottom: 0;
    justify-content: space-between;
    z-index: 3;
    box-shadow: inset 0px 1px 0px #dee2e6;

    &__logo {
      display: none;
    }

    &__navItem {
      margin: 0;
      padding-left: 0 !important;

      .title {
        display: none !important;
      }
    }

    .dummy__div {
      display: none;
    }

    .logout__link__desktop {
      display: none;
    }

    .notifications__navItem {
      padding-left: 0;
      width: fit-content;

      .title {
        display: none !important;
      }
    }

    .logout__link__mobile {
      display: block;
      border: none;
      padding: 0;
    }

    &__iconsWrapper {
      flex-direction: initial;
      width: 100%;
      max-height: initial;
      overflow-y: initial;
      overflow-x: initial;
      display: flex;
      align-items: center;
    }

    .open-button {
      display: none;
    }

    &__notificationIcon {
      &__badgeAndBell {
        padding-top: initial;
      }
    }
  }

  .notifications {
    top: initial;
    bottom: 7.9rem;
    height: calc(100vh - 8rem);
    left: 0;

    @supports not (-webkit-touch-callout: none) {
      &__header {
        margin-top: 4rem;
      }
    }

    @supports (-webkit-touch-callout: none) {
      &__header {
        margin-top: 10rem;
      }
    }
  }
}

.ant-badge-multiple-words {
  padding: 0 0.5rem;
}

@media (max-width: @screen-sm) {
  .sideNavigation {
    padding: 0 2rem;
    height: @footer-height-mobile;

    &__iconsWrapper {
      justify-content: space-evenly;

      .user__navItem {
        order: 1;
      }

      .notifications__navItem {
        order: 2;
      }

      .dashboard__navItem--mobile {
        order: 3;
      }
    }

    &__navItem {
      svg {
        font-size: 2rem;
      }
    }

    .dashboard__navItem--mobile {
      display: block;
    }

    &__notificationIcon {
      &__badgeAndBell {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .logout__link__mobile,
    .settings__navItem,
    .sales__navItem,
    .planning__navItem,
    .reporting__navItem {
      display: none;
    }
  }

  .notifications {
    bottom: @footer-height-mobile;
    height: 100vh;
    padding-top: 8.4rem;
    width: 100vw;
  }
}
