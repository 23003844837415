.offer-and-comments-wrapper {
  display: flex;
  gap: 2.8rem;
  margin-top: 2.5rem;
  padding: 0 2.7rem;

  .client-information__affix {
    z-index: 1;

    ::-webkit-scrollbar-track {
      background: #dee2e6;
      border-radius: 15px;
    }

    ::-webkit-scrollbar-thumb {
      background: #adb5bd;
      border-radius: 15px 15px 0px 0px;
    }

    .information__section {
      border-radius: 8px;
      height: fit-content;
      background-color: transparent;
      padding-bottom: 0;
    }
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar {
    width: 0.2rem;
  }
}

.client-information {
  .edit__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.project__data__modal {
  width: 100% !important;
  z-index: 1;

  .client-information__header {
    padding-top: 1.6rem;
  }

  .project__header__container {
    background-color: transparent;
    border-bottom: none;
  }

  ::-webkit-scrollbar {
    display: none !important;
  }

  &__container {
    width: 100%;
  }

  .field-value__status {
    width: 40% !important;
  }
}

.offer-and-comments {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;

  .offers {
    display: flex;
    flex-direction: column;
    .header-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.4rem;

      .offers__count__container {
        .folder__icon {
          display: none;
          width: 42px;
          height: 42px;
          background: #3d92ec;
          box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
            0px 6px 16px rgba(0, 0, 0, 0.08),
            0px 3px 6px -4px rgba(0, 0, 0, 0.12);
          border-radius: 100px;
          justify-content: center;
          align-items: center;
        }
      }

      h5 {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 3.2rem;
        color: #343a40;
        font-size: 2.4rem;
        margin-bottom: 0;
      }
      .buttons {
        height: 4rem;

        .inspection {
          border: 0.1rem solid #dee2e6;
          border-radius: 1.2rem;
          display: inline-flex;
          background: #fff;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          margin-right: 0.8rem;
          max-height: 100%;
          cursor: pointer;

          span {
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.4rem;
            padding-right: 1.6rem;
            padding-left: 1.6rem;
          }

          div {
            background: #3d92ec;
            padding: 1.2rem;
            border-radius: 0rem 1.2rem 1.2rem 0rem;
            max-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .new-offer {
          border: 0.01rem solid #dee2e6;

          background: #3d92ec;
          height: 4rem;
          border-radius: 1.2rem;
          cursor: pointer;
          box-sizing: border-box;
          padding: 0;
          .offer-text {
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #fff;
            margin-right: 1.4rem;
          }

          .plus {
            margin-left: 2.1rem;
            margin-right: 2.1rem;
            position: relative;

            .vertical {
              border: 0.1rem solid #fff;
              height: 1.6rem;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            .horizontal {
              border: 0.1rem solid #fff;
              width: 1.6rem;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        .new-offer-disabled {
          cursor: not-allowed;
          border-color: #d9d9d9;
          color: rgba(0, 0, 0, 0.25);
          background-color: rgba(0, 0, 0, 0.04);
          box-shadow: none;
        }
      }
    }

    .not-opened {
      :hover {
        background: #fbfbfb;
      }
    }

    .offer {
      display: flex;
      flex-direction: column;
      margin-top: 1.8rem;

      .header__opened {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }

      .header-and-project-steps {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0rem 0.4rem 1rem rgba(0, 0, 0, 0.06);
        border-radius: 0.8rem;

        .project-step {
          min-width: fit-content;
        }

        .offer-header {
          display: flex;
          justify-content: space-between;
          padding-top: 2rem;
          padding-bottom: 1.6rem;
          padding-right: 2.2rem;

          .allinview__trash__container {
            gap: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .trash__icon {
              display: flex;
              align-items: center;
              cursor: pointer;
            }
          }

          .open-close {
            margin-left: 1.6rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            transition-duration: 0.3s;

            span {
              font-weight: 700;
              font-size: 1.4rem;
              line-height: 2.4rem;
              color: #000;
              margin-right: 1.2rem;
            }

            img {
              cursor: pointer;
              transform: rotate(360deg);
              transition-duration: 0.3s;
            }
          }
          .closed {
            img {
              transform: rotate(180deg);
              transition-duration: 0.3s;
            }
          }

          .sending-indicator {
            background: #f8f9fa;
            border-radius: 0.4rem;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 2.2rem;
              color: #faad14;
              padding: 0.2rem 1rem;
            }

            img {
              padding-right: 1rem;
            }
          }

          .offer-status-changed {
            span {
              color: #7cb305;
            }
          }

          .disabled__allinview__btn {
            .title,
            .allinview-logo {
              cursor: not-allowed;
            }

            .allinview-logo {
              filter: grayscale(100%);
              opacity: 50%;
            }

            .title {
              color: #d4d4d4;
            }
          }

          .allinview {
            cursor: pointer;
            border: 0.12rem solid #e9ecef;
            border-radius: 0.2rem;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            :hover {
              background: #fff;
            }
            span {
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 2.4rem;
              padding-left: 0.8rem;
              padding-right: 0.8rem;
            }

            img {
              height: 100%;
            }

            .allinview-logo {
              margin-right: 0.5rem;
            }
          }

          .allinview-logo {
            width: 2.4rem;
          }
        }

        ::-webkit-scrollbar {
          display: none !important;
        }

        .steps__container {
          :hover {
            background: #fff;
          }
          .project-steps {
            overflow-x: auto;
            border-bottom: 0.1rem solid #e9ecef;

            &__products {
              display: flex;
              gap: 0.8rem;
              align-items: center;

              .edit__icon {
                display: flex;
                align-items: center;
              }
            }

            .steps {
              display: flex;
              align-items: center;
              gap: 8%;
              padding: 0 2rem;

              p {
                cursor: pointer;
                padding: 1.1rem 0;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 2.4rem;
                color: #606870;
                margin-bottom: 0;
              }

              .active {
                color: #212529;
                font-weight: 600;
                border-bottom: 0.2rem solid #3d92ec;
                border-radius: 4px 4px 0px 0px;

                .products__step__title {
                  font-weight: 600;
                  color: #212529;
                }
              }

              .products {
                display: flex;
                justify-content: center;
                align-items: center;
                div {
                  height: 2.4rem;
                  padding: 0.5rem;
                  background: #f8f9fa;
                  border-radius: 0.2rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 0.8rem;
                }

                img {
                  width: 1.2rem;
                  height: 1.2rem;
                  filter: invert(59%) sepia(56%) saturate(4680%)
                    hue-rotate(190deg) brightness(99%) contrast(87%);
                }
              }

              .quotation-step {
                display: flex;
                justify-content: center;
                align-items: center;
                div {
                  width: 0.8rem;
                  height: 0.8rem;
                  background: #7cb305;
                  border-radius: 50%;
                }
                span {
                  padding-left: 0.8rem;
                  padding-right: 0.8rem;
                }
              }
            }

            .dummy__step {
              display: none;
            }
          }
        }
      }
    }

    .products-wrapper {
      background: #ffffff;
      box-shadow: 0rem 0.4rem 0.6rem rgba(0, 0, 0, 0.06);
      border-radius: 1.2rem;
      display: grid;
      grid-gap: 0.8rem;
      gap: 0.8rem;
      grid-template-columns: 1fr 1fr;
      padding: 1.6rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .product {
        background: #f1f3f5;
        border-radius: 0.4rem;
        padding: 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.8rem;
        min-width: 50%;
        flex-grow: 1;

        .product-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.8rem;
          align-items: center;
          word-break: break-all;

          h6 {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #212529;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            overflow: auto;
          }
          span {
            display: block;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #343a40;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 70%;
            overflow: auto;
          }
        }
      }
    }
  }

  .signature__error__message {
    padding-left: 0;
  }

  .empty__signature__error {
    height: 4.4rem;
  }

  .quotation__date__picker__error {
    border: 1px solid @warning-red;

    .ant-picker-suffix {
      color: @warning-red;
    }
  }

  .dummy__div {
    display: none;
  }
}

@media (max-width: @screen-lg) {
  .products-wrapper {
    grid-template-columns: 1fr !important;
  }

  .offer-and-comments-wrapper {
    padding: 0 2.4rem;

    .project-steps {
      .steps {
        justify-content: space-between;
        gap: 0 !important;
      }
    }
  }
}

@media (max-width: @screen-lg) {
  .offer-and-comments-wrapper {
    padding: 0 1.8rem;

    .offer-and-comments {
      margin-left: 0;
    }

    .client-information__affix {
      display: none;
    }

    .header-wrapper {
      flex-direction: column;

      .buttons {
        margin-bottom: 0.8rem;

        .new-offer {
          padding: 0 2rem !important;
        }
      }
    }

    .offers__count__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 2.3rem;
      border-bottom: 1px solid #e9ecef;
      margin-bottom: 1.6rem;

      .folder__icon {
        display: flex !important;
      }
    }
  }
}

@media (max-width: @screen-lg) {
  .project {
    padding-bottom: 7rem;
  }

  .project__data__modal__wrapper {
    .modalContent {
      padding-top: 0;
    }
  }

  .project__modal__wrap {
    bottom: auto !important;
  }

  .project__data__modal {
    background-color: #f8f9fa;
    display: block;

    .client-information {
      display: block !important;
    }

    &__container {
      padding-bottom: 0;

      .client-info-fields {
        padding-bottom: 0;
        padding-right: 0;
      }

      .client-information__header {
        margin-right: 0;
        padding-top: 0;
      }
    }
  }
}

@media (max-width: @screen-sm) {
  .offer-and-comments-wrapper {
    .offer-header {
      padding-right: 0 !important;
      display: grid !important;
      grid-template-columns: 1fr 1fr;

      .allinview {
        width: 14rem !important;
      }

      .allinview__trash__container {
        justify-content: end !important;
        gap: 1.6rem !important;
      }

      .allinview__btn {
        padding-right: 0;
      }

      .open-close {
        display: block !important;
      }

      .sending-indicator {
        justify-content: end !important;
        background-color: transparent !important;
        padding-right: 1.6rem;

        span {
          background: #f8f9fa;
          border-radius: 4px;
        }
      }

      .allinview__btn {
        width: 100%;
        display: flex;
        justify-content: end;
        padding-right: 1.6rem;
        padding-top: 1.6rem;
        border-top: 1px solid #f8f9fa;
        margin-top: 1.6rem;

        .allinview {
          margin-right: 0 !important;
          justify-content: space-between !important;
        }
      }
    }

    .header-wrapper {
      .buttons {
        display: flex;

        .inspection {
          width: 40% !important;
          margin-right: 0;
        }
      }

      .new-offer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60% !important;
      }
    }

    .dummy__div {
      display: block;
      height: 4.3rem;
      width: 100%;
      margin-top: 1.6rem;
      border-top: 1px solid #f8f9fa;
    }

    .offer__open {
      padding-top: 0 !important;
      margin-top: 0 !important;
      border-top: 0 !important;
    }

    .hide {
      display: none !important;
    }

    .project-steps {
      border-top: none !important;

      p {
        padding: 1.1rem 1rem !important;
      }

      .steps {
        gap: 1.8rem !important;
      }
    }
  }

  .project__data__modal {
    .field-value__status {
      width: 60% !important;

      .option {
        span {
          max-width: 9rem !important;
        }
      }
    }
  }
}

@media (max-width: 405px) {
  .header-wrapper {
    .buttons {
      gap: 0.8rem;

      .new-offer {
        .offer-text {
          margin-right: 0 !important;
        }
      }
    }
  }
}
