.searchForm {
  width: 300px;
  height: 38px;
  position: relative;

  .searchInput {
    width: 0;
    height: 38px;
    float: right;
    transition: all 0.4s ease-in-out;
    font-size: 16px;
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 12px;
    border: solid 1px #3d92ec;
    font-weight: 3 00;
    outline: none;
  }

  .searchInput--active {
    width: 100%;
  }

  .searchInput-focused {
    border: solid 1px #1f73e2;
  }
  .searchButton {
    width: 38px;
    height: 38px;
    position: absolute;
    border: none;
    border-radius: 12px;
    right: 0;
    top: 0;
    background-color: #3d92ec;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    svg {
      color: white;
      font-size: 30px;
    }
  }

  .searchButton-focused {
    background-color: #1f73e2;
  }
  ::placeholder {
    color: #e8e8e8;
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #e8e8e8;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #e8e8e8;
  }
}

@media (max-width: @screen-lg) {
  .searchForm {
    .searchInput--active {
      background: transparent;
    }
  }
}
