.errorModal {
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 6px;
      width: 100%;
      background: rgba(255, 77, 79, 1);
    }
  }
  .ant-modal-body {
    display: flex;
    align-items: center;
    padding-top: 46px;
    padding-bottom: 46px;
  }
  &__content {
    border-left: 1px solid #dee2e6;
    padding-left: 24px;
    margin-left: 24px;
    width: 100%;
  }
}
