.profile {
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 4rem;

  &__title {
    font-size: 2.4rem;
    margin-top: 5rem;
    color: #343a40;
  }

  &__info__container__loading {
    opacity: 0.6;
  }

  &__info__container {
    position: relative;
    padding: 4rem;
    padding-top: 9rem;
    width: 90%;
    max-width: 118rem;
    margin-top: 10rem;
    background-color: white;
    box-shadow: 0px 6px 6px 0 rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    &__letters {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      overflow: hidden;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 14rem;
        width: 14rem;
        border-radius: 100px;
        background-color: #d0eafe !important;
        color: #1f73e2;
        font-size: 5rem;
        font-weight: 700;
      }
      p {
        margin-bottom: 0 !important;
      }
    }

    .loading_icon {
      padding-top: 2rem;
      margin: 0 auto;
      width: 100%;
    }

    .profile__form {
      margin: 0 auto;
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 70rem;

      .ant-row {
        width: 100%;
        flex-wrap: nowrap;

        label {
          display: flex;
          justify-content: end;
          width: 125px;
        }
      }

      &__buttons {
        width: 100%;
        display: flex;
        gap: 1rem;
        justify-content: end;
      }

      .disabled__password__container {
        position: relative;

        input {
          cursor: default;
          background-color: white;
          padding-top: 0.8rem;
          color: #343a40;
        }

        &__edit {
          position: absolute;
          font-size: 1.5rem;
          cursor: pointer;
          bottom: 2.7rem;
          right: 1rem;
          transform: translateY(-20%);
        }
      }

      .signature__view {
        &__container {
          padding-left: 2.2rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 2rem;

          .signature__title {
            color: rgba(0, 0, 0, 0.85);
            font-size: 1.4rem;
            font-weight: 200;
            width: 10.5rem;
          }

          .signatures__box {
            border: 0.5px solid #d9d9d9;
            width: 27rem;
          }

          .signatures__buttonBox__image {
            height: 11rem;
          }
        }

        &__content {
          display: flex;
          width: 100%;
        }
      }

      .signatureImgAndDeleteWrapper {
        width: 27rem;
        display: flex;
        justify-content: center;

        .ant-image-mask {
          border-radius: 8px;
        }

        .ant-spin-nested-loading {
          .ant-spin-container {
            width: 24rem;

            .ant-image {
              width: 24rem;
            }
          }
        }

        img {
          width: 100%;
        }
      }

      .lang__container {
        justify-content: space-between;
        width: 100%;

        .ant-form-item {
          margin-bottom: 0;
        }
      }

      .enter__signature {
        &__button {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }
}

.signature__preview__group {
  .ant-image-preview-img {
    background-color: white;
  }
}

@media (max-width: @screen-lg) {
  .profile {
    padding-bottom: 10rem;

    &__title {
      margin-top: 3rem;
    }

    &__info__container {
      margin-top: 3rem;
      padding: 2rem;
      padding-bottom: 3rem;

      &__letters {
        display: flex;
        justify-content: center;
        position: relative;
        transform: none;
        margin-bottom: 3rem;
        margin-top: 2rem;
        left: 0;

        div {
          height: 10rem;
          width: 10rem;
          font-size: 4rem;
        }
      }

      .profile__form {
        width: 90%;
      }
    }
  }

  .change__password__container .ant-form-item {
    flex-direction: column;
    align-items: center;

    .ant-form-item-control {
      width: 100%;
    }

    .ant-form-item-label {
      max-width: inherit;
      width: 100%;
      text-align: left;
      padding-left: 9%;
    }
  }
}

@media (max-width: @screen-sm) {
  .profile {
    padding-bottom: 11rem;

    &__title {
      margin-top: 1rem;
    }

    &__info__container {
      padding: 1rem;

      .profile__form {
        padding-bottom: 2rem;

        .ant-row {
          flex-direction: column;

          label {
            justify-content: start;
          }
        }

        .signature__view {
          &__container {
            padding-left: 0;
          }

          &__content {
            flex-direction: column;
            gap: 0;
          }
        }
      }
    }
  }

  .change__password__container .ant-form-item {
    .ant-form-item-label {
      padding-left: 0;
    }
  }
}

@media (max-width: 340px) {
  .profile {
    .signatures__box {
      width: 24rem !important;
    }
  }
}
