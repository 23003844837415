.comments {
  margin-top: 4rem;
  background: #ffffff;
  border: 0.1rem solid #f1f3f5;
  box-shadow: 0rem 0.4rem 0.6rem rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1.6rem 3.2rem 3.2rem 1.6rem;

  h5 {
    border-bottom: 0.1rem solid #f1f3f5;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.2rem;
    padding-bottom: 1.6rem;
    color: #212529;
  }

  .comments-content {
    margin-top: 1.6rem;
    margin-left: 1.6rem;

    .comment-input {
      background: #ffffff;
      border: 0.1rem solid #dee2e6;
      padding: 1.6rem;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #606870;
      opacity: 1;
      height: auto;
      margin-bottom: 0;
    }
    .comment-input::placeholder {
      opacity: 1;
      color: #606870;
    }

    .comment-input:focus {
      box-shadow: none;
    }
  }

  .comment {
    display: flex;
    gap: 1.6rem;
    margin-top: 1.6rem;

    .user-image-wrapper {
      width: 4rem;
      width: 4rem;
      height: 4rem;
      padding: 0.3rem;
      border: 0.1rem solid #dee2e6;

      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .comment-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-bottom: 1px solid #e9ecef;

      .days-passed::first-letter {
        text-transform: capitalize;
      }

      h6 {
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 2rem;
        color: #343a40;
        margin: 0;
      }
      span {
        width: fit-content;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: #868e96;

        .comment-time {
          border: 0.1rem solid #fff;
          display: flex;
        }
      }

      .comment__text {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin-top: 1.6rem;
        color: #343a40;
        word-break: break-all;
      }
    }
  }
}

.load-more-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.6rem;
  width: 100%;
}

.comment-time {
  display: flex;
  align-items: center;
  color: #f8f9fa;
  font-size: 1.2rem;
  line-height: 1.6rem;
  gap: 0.4rem;

  div {
    width: 0.5rem;
    height: 0.5rem;
    background: #495057;
    border-radius: 50%;
  }
}
