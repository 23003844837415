.selectlanguage {
  display: flex;
  align-items: center;

  &__box {
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .flagUk {
      width: 2.5rem;
      height: 2.5rem;
    }
    .flagDutch {
      width: 3.5rem;
      height: 3.5rem;
      margin-top: 0.5rem;
    }

    border-radius: 100%;
  }
  .active {
    border: 1px solid #3d92ec;
    background-color: #e9f3f9;
  }
}
