.delivery-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    margin-top: 7rem;
  }

  .delivery {
    width: 100%;
    background-color: #fff;
    position: relative;
    border-radius: 24px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    flex-direction: column;

    &__footer {
      padding-top: 4rem;
      width: 100%;
    }

    .ant-table-tbody {
      white-space: break-spaces;
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    /* Materials Delivery */

    .materials {
      width: 100%;

      &__title {
        font-size: 1.4rem;
        text-transform: uppercase;
      }

      &__content {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .contentbox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
          font-weight: bold;
          font-size: 1.4rem;
          width: 50%;
        }
        &__text {
          color: #343a40;
          font-size: 1.4rem;
          width: 50%;
          padding-top: 2rem;
        }
      }
    }
    /* Table Delivery */
    &__table {
      width: 100%;
      margin-top: 4rem;
    }
  }
}

.uploaddoc {
  width: 100%;
  margin-top: 4rem;

  .ant-upload {
    height: 17rem;
  }

  &__uploaded-documents {
    display: flex;
    align-items: flex-start;
    gap: 4rem;
    flex-wrap: wrap;
    overflow-x: hidden;

    a {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 50rem;
    }
  }
}

@media (max-width: @screen-lg) {
  .delivery-section {
    margin-bottom: 0rem;
    .delivery {
      padding: 3rem;
      width: 100%;
      padding-bottom: 0;
      &__footer {
        position: initial;
        padding-top: 3rem;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding-bottom: calc(@footer-height + 2.4rem);
      }
    }
  }

  .uploaddoc {
    &__uploaded-documents {
      a {
        max-width: 35rem;
      }
    }
  }
}

@media (max-width: @screen-sm) {
  .delivery-section {
    .delivery {
      &__footer {
        padding-bottom: calc(2rem + @footer-height-mobile);
      }
    }
  }

  .uploaddoc {
    .ant-upload {
      height: 23rem !important;
    }

    &__uploaded-documents {
      a {
        max-width: 17rem;
      }
    }
  }
}
