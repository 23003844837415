.lead-iframe-wrapper {
  background: #f8f9fa;
  min-height: 100vh;
  font-family: open-sans, system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  padding-top: 5rem;
  img {
    max-width: 17rem;
    height: 4rem;
    width: 17rem;
    height: auto;
    max-height: 10rem;
  }
}

.header-and-form {
  width: 90%;
  max-width: 90rem;
  .header-and-switcher {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.8rem;
  }
  h2 {
    display: flex;
    align-items: center;

    margin: 0;
    font-weight: 600;
    font-size: 2.4rem;
  }
}

.lead-iframe {
  height: 100%;

  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  border-radius: 2.4rem;
  padding: 6rem 12rem;
  color: #212529;

  .form-input {
    display: flex;
    flex-direction: column;

    .ant-input {
      border-radius: 8px;
      border: 1px solid #dee2e6;
      width: 100%;
      height: 4rem;
      width: 80rem auto;
    }
  }

  .client-type {
    .ant-form-item-label > label {
      font-weight: 600;
      margin-right: 5.6rem;
    }
    .ant-radio-wrapper {
      margin-right: 3.3rem;
    }
  }

  .ant-form-item {
    margin-bottom: 3.2rem;
  }

  .ant-form-item-label {
    text-align: left;
    margin-bottom: 0.8rem;
  }

  .ant-form-item-label > label {
    font-weight: initial;
  }

  .ant-form-item-label > label:after {
    content: '';
  }

  .zip-code-and-house-number {
    display: flex;
    gap: 4rem;
    .zipcode,
    .house-number {
      width: 50%;
    }
  }

  .lead-iframe-button {
    margin-top: 2rem;
    height: 4rem;
  }

  .language-switcher {
    position: absolute;
    top: 4rem;
    right: 4rem;
  }

  .iframe-error {
    padding: 0;
  }
}

.iframe-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 200;

  .token,
  .iframe-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    gap: 1rem;

    svg {
      cursor: pointer;
      width: 2rem;
      height: 2rem;
    }

    .iframe-embed-code {
      display: flex;
      align-items: center;
      border: 0.07rem solid black;
      border-radius: 0.4rem;
      font-weight: 300;
      padding: 1rem;
    }

    .insertion-text {
      font-weight: 400;
      font-size: large;
    }
  }

  .text-and-value {
    :first-child {
      width: 10rem;
    }
    display: flex;
    align-items: center;
    gap: 1rem;
    p {
      display: flex;
      align-items: center;
      max-width: 40rem;
      overflow-x: auto;
      margin: 0;
    }
  }
}

@media (max-width: @screen-lg) {
  .header-and-form {
    width: 100%;
  }
  .lead-iframe {
    border-radius: 0;
    padding: 3rem 4rem;

    .ant-row {
      flex-direction: column;
    }
    .client-type {
      .ant-radio-group {
        display: flex;
      }
    }

    .recaptcha {
      transform: scale(0.93);
      transform-origin: 0 0;
    }
  }
}

@media (max-width: @screen-sm) {
  .lead-iframe {
    padding: 3rem 4rem;

    .zip-code-and-house-number {
      flex-direction: column;
      gap: 0;
      .zipcode,
      .house-number {
        width: 100%;
      }
    }
    .recaptcha {
      transform: scale(0.68);
      transform-origin: 0 0;
    }

    .ant-form-item-label {
      margin-bottom: 0.6rem;
    }
  }

  .header-and-form {
    h2 {
      font-size: 2rem;
    }
  }

  .iframe-info {
    .text-and-value {
      p {
        max-width: 10rem;
      }
    }
  }
}
