.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination-next .ant-pagination-item-link:hover {
  border: none;
  border-radius: 8px;
  background-color: rgba(208, 234, 254, 0.4);
}

.ant-pagination-disabled:hover .ant-pagination-item-link {
  background-color: #dfdfdf;
}

.ant-table-pagination.ant-pagination {
  margin-top: 4rem;
}

.ant-pagination-options {
  display: none;
}

.ant-table-pagination {
  justify-content: center;
  align-items: center;

  .ant-pagination-item {
    background-color: transparent;
    border: none;
    border-radius: 8px;
  }

  .ant-pagination-item:hover,
  .ant-pagination-item-active {
    background-color: transparent;
    border: none;
    a {
      color: #1f73e2;
      font-weight: 600;
    }
  }

  .ant-pagination-item-link {
    background-color: transparent;

    .anticon svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
}
