.confirmation-modal {
  .agreeDelete__footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .agreeDelete__content {
    width: 100%;
    font-size: 1.6rem;
    color: #60666c;
    margin-bottom: 1rem;
  }

  .ant-modal-content {
    border-radius: 1.2rem;
  }

  .ant-modal-header {
    border-radius: 1.2rem 1.2rem 0 0;

    .ant-modal-title {
      .custom-title {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }
}

.ant-modal-wrap {
  z-index: 10000;
}
