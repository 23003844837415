.quotationCloneWrapper {
  margin-left: 20%;
  margin-right: 20%;
  padding-bottom: 5rem;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
  img {
    max-width: 30rem;
    height: auto;
    max-height: 10rem;
  }
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  font-size: 2rem;
}

@media (max-width: @screen-lg) {
  .quotationCloneWrapper {
    margin-left: 5%;
    margin-right: 5%;
  }
}
