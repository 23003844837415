.confirm__order {
  padding: 8rem 0;
  background-color: white;
  box-shadow: 0rem 0.4rem 0.6rem rgba(0, 0, 0, 0.06);
  border-radius: 0 0 8px 8px;

  &__container {
    display: flex;
    justify-content: center;
    gap: 6.6rem;
    flex-direction: column;

    &__warning {
      display: flex;
      flex-direction: column;
      gap: 1.9rem;
      align-items: center;

      .warning__text {
        font-weight: 600;
        font-size: 1.6rem;
        text-align: center;
        color: #606870;
        max-width: 21.4rem;
      }

      .confirm__checkbox {
        .ant-checkbox {
          .ant-checkbox-inner {
            border: 1px solid #0a53d4;
          }
        }

        p {
          font-size: 1.4rem;
          color: #343a40;
          margin-bottom: 0;
        }
      }
    }

    .confirm__order__button {
      background: #3d92ec !important;
      border-radius: 12px;
      color: white;
      font-weight: 600;
      font-size: 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: fit-content;
      border: none;
      height: 4rem;
    }

    .confirm__order__button__disabled {
      color: #868e96;
      background-color: #e9ecef !important;
    }
  }
}
