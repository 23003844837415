input:-webkit-autofill {
  -webkit-background-clip: text;
  background-clip: text;
}

.ant-input {
  border-radius: 8px;
  font-weight: 200;
}
.ant-form-item-label > label {
  font-weight: 200;
}

.labelError {
  color: @main-red;
}

.custom-antd-select-item {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.custom-antd-input-item {
  .ant-form-item {
    margin-bottom: 0;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.custom-antd-number-format-input-item {
  .ant-form-item {
    margin-bottom: 0;

    input {
      width: 100%;
      border-radius: 8px;
      font-weight: 200;
      border: 1px solid #d9d9d9;
      padding: 0.4rem 1.1rem;
      transition: all 0.3s ease;
    }

    input:hover {
      border-color: #69b4fa;
      border-right-width: 1px;
    }

    input:focus {
      border-color: #69b4fa;
      box-shadow: 0 0 0 2px rgba(61, 146, 236, 0.2);
      border-right-width: 1px;
      outline: 0;
    }
  }
}

/* select */
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
}

.ant-picker {
  width: 100%;
  border-radius: 8px !important;
}

.ant-input-affix-wrapper {
  border-radius: 8px;
}

.ant-form-item-explain-error {
  padding-left: 1.3rem;
  color: @warning-red;
  font-style: italic;
  font-weight: 400;
}

.text-editor-error {
  .editor-wrapper {
    border: 1px solid @warning-red !important;

    .controls-wrapper {
      border-bottom: 1px solid @warning-red !important;
    }
  }

  .ant-form-item-label > label {
    color: @warning-red !important;
  }
}

.ant-selector-error .ant-select-arrow {
  color: @warning-red !important;
}

.ant-selector-error .ant-select-selector,
.ant-input-border-error input,
.error-border {
  border: 1px solid @warning-red !important;
}

.form-item-border-error {
  .ant-input-affix-wrapper {
    border: 1px solid @warning-red !important;
  }
}

.error-message-color {
  color: @warning-red !important;
}

.text-editor {
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.company-text-editor {
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.label-error {
  label {
    color: @warning-red !important;
  }
}

.antd-select-error {
  .ant-select-selector {
    border: 1px solid @warning-red !important;
  }

  .ant-select-arrow {
    color: @warning-red !important;
  }
}

.sm-input-with-error {
  min-height: 5.6rem;
  margin-bottom: 1rem;

  input {
    min-height: 3.2rem;
  }
}

.sm-input-min-height-error {
  input {
    min-height: 3rem !important;
  }
}

.pdf-upload-error {
  .anticon-paper-clip,
  .ant-upload-list-item-name,
  label {
    color: @warning-red !important;
  }
}

@media (max-width: @screen-sm) {
  .sm-input-with-error {
    .ant-form-item-control-input {
      justify-content: start;
      align-items: flex-start;
      min-height: 5.6rem;
    }

    .ant-form-item-label {
      padding-bottom: 0;
      height: fit-content;
    }
  }

  .custom-antd-input-item {
    .ant-form-item-control-input {
      min-height: 3.2rem !important;
    }
  }

  .custom-antd-number-format-input-item {
    .ant-form-item-control-input {
      min-height: 3.2rem !important;
    }
  }
}
