.signatureModal_content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 10rem);
  &__canvas {
    border: 0.8px solid #63aff4;
    border-radius: 9px;
    margin-top: 3.9rem;
    width: 100%;
    height: 20rem;
  }

  &__clear {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
    margin-bottom: 13.3rem;
  }
}

.signatureImg {
  border: 0.8px solid #63aff4;
  border-radius: 9px;
  max-height: 200px;
  width: 100%;
}

.deleteSignatureImg {
  position: absolute;
  right: -1.2rem;
  top: -1.2rem;
}

.signatureImgAndDeleteWrapper {
  position: relative;
}

@media (max-width: @screen-lg) {
  .signatureModal_content {
    &__canvas {
      height: 30rem;
    }
  }
}

@media (max-width: @screen-sm) {
  .signatureModal_content {
    width: 90%;
    &__canvas {
      height: 20rem;
    }
  }
}
