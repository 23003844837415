.controls-button {
  margin: 0.2rem;
  padding: 0.2rem 0.5rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.85);
  border-radius: 0.2rem;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);
}

.controls-button-active {
  color: #3d92ec;
  border: 0.1rem solid #3d92ec;
}

.block-controls-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.inline-controls-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.controls-wrapper {
  border-bottom: 0.1rem solid #d9d9d9;
  margin: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.editor-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 0.5rem;
  min-height: 20rem;
}
