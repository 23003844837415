#root {
  background-color: none !important;
}
.login {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .logincard {
    width: 64.4rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.9);
    padding-top: 5.3rem;
    padding-bottom: 6.8rem;
    backdrop-filter: blur(10px);

    &__language__selector {
      position: absolute;
      top: 2rem;
      right: 2rem;

      .ant-select {
        .ant-select-selector {
          background-color: transparent;
        }
      }
    }

    &__logo {
      width: 23.5rem;
    }
    &__title {
      color: #212529;
      font-size: 2.4rem;
      margin-top: 4.8rem;
      font-weight: 700;
    }
    &__subtitle {
      margin-top: 2.8rem;
      color: #606870;
      font-size: 16px;
    }

    &__form {
      width: 70%;
      display: flex;
      flex-direction: column;
      margin-top: 4rem;

      .ant-form {
        .ant-form-item {
          margin-bottom: 0.5rem;
        }
      }

      .ant-form-item-control-input-content {
        height: 7.8rem;
      }

      .ant-input-affix-wrapper-focused {
        box-shadow: none;
      }

      .ant-input-affix-wrapper {
        height: 5.6rem;
        padding: 0 2.1rem;
        background-color: transparent;

        input {
          padding-left: 2.5rem;
          color: '#212529';
          background-color: transparent;
        }
      }

      &__submit__button {
        width: 100%;
        height: 5.6rem;
        margin-top: 1.2rem;
        font-weight: 600;
        font-size: 1.6rem;
      }
    }

    &__forgotpassword {
      margin-top: 3.2rem;
      text-align: center;
      color: #868e96;
      width: 100%;
      display: block;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
  .showLoginError {
    margin: 1rem;
  }

  .password-reset-success {
    color: green;
    font-size: 1.6rem;
    padding-top: 2rem;
  }
}

@media (max-width: @screen-lg) {
  .login {
    .logincard {
      margin-left: 5rem;
      margin-right: 5rem;

      &__form {
        .ant-input-affix-wrapper {
          height: 5rem;
          padding: 0 1.5rem;

          input {
            padding-left: 1rem;
          }
        }

        &__submit__button {
          height: 5rem;
        }

        .ant-form-item-control-input-content {
          height: 7.2rem;
        }
      }

      &__subtitle {
        text-align: center;
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }
}

@media (max-width: @screen-sm) {
  .login {
    .logincard {
      width: 95%;
      margin-left: 2rem;
      margin-right: 2rem;
      padding: 3.5rem 0;

      &__language__selector {
        top: 1rem;
        right: 1rem;
      }

      &__form {
        width: 85%;
      }
    }
  }
}
