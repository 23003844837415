.fusebox_radio_group {
  margin-bottom: 0;
  flex-direction: row;
  height: '5.8rem';
  margin-bottom: 1rem;

  &__item {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    text-align: start;
  }

  .ant-form-item-label-error {
    color: #ff4d4f;
  }
}

.add-inspection-form {
  .sufficient-range,
  .ampere {
    label {
      white-space: initial;
      text-align: start;
    }

    ::after {
      display: none;
    }
  }
}

.inspection-company-logo {
  margin: 2rem auto;
  max-height: 100px;
}

.cupboard_suitable_for_system {
  padding-top: 0;
}

@media (max-width: 791px) {
  .cupboard_suitable_for_system {
    padding-top: 2.3rem;
  }
}

@media (max-width: @screen-sm) {
  .cupboard_suitable_for_system {
    padding-top: 0;
  }
}
