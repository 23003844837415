.ant-table-thead > tr > th {
  background-color: #f1f3f5;
}

.ant-table-row {
  cursor: pointer;
}

.ant-table {
  white-space: nowrap;
}

.custom-antd-table {
  width: 100%;

  .ant-table-container {
    background-color: transparent;
  }

  .ant-table-content {
    border-radius: 12px;
  }

  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: none;
  }

  .ant-table {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.06));
    border-radius: 15px;
  }

  .ant-table-thead {
    .ant-table-cell {
      font-size: 1.4rem;
      color: #212529;
      font-weight: 600;
    }
  }

  .ant-table-cell {
    border-right: 1px solid #f1f3f5;
    border-bottom: 1px solid #f1f3f5;
  }

  .ant-table-cell:last-of-type {
    border-right: none;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    color: #343a40;
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #7f8082;
  }

  .ant-table-selection-column {
    padding: 2rem;
  }
}

@media (max-width: @screen-lg) {
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    font-size: 1.2rem;
  }
}

@media (max-width: @screen-sm) {
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    font-size: 1rem;
  }
}
