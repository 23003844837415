.planning-calendar {
  font-family: open-sans, sans-serif, Arial, Helvetica;
  text-transform: capitalize;
  margin-bottom: calc(@footer-height + 2.4rem);

  .rbc-month-view {
    z-index: 1;
  }

  .rbc-month-view,
  .rbc-time-view {
    background: #fff;
    border: 1px solid #e9ecef;

    .rbc-header {
      border-bottom: none;
      font-size: 1.3rem;
      color: #495057;
      font-weight: 600;
      padding-top: 1.2rem;
    }

    .rbc-month-row {
      .rbc-date-cell {
        font-size: 1.1rem;
        line-height: 1.6rem;
        font-weight: 400;
        color: #495057;
      }

      .rbc-off-range-bg {
        background: #fff;
      }
    }
  }

  .rbc-time-view {
    .rbc-header {
      .rbc-button-link {
        font-size: 1.1rem;
      }
    }
  }

  .rbc-day-bg + .rbc-day-bg {
    height: 200px;

    border-left: 1px solid #e9ecef;
  }

  .rbc-month-row + .rbc-month-row {
    height: 200px;
    border: 1px solid #e9ecef;
  }

  .rbc-toolbar {
    margin-bottom: 2.2rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    position: absolute;
    top: 0;
    gap: 1rem;
  }

  .rbc-current-time-indicator {
    background: #85a5ff;
  }

  .rbc-time-view {
    .rbc-row {
      min-height: auto;
    }

    .rbc-time-header,
    .rbc-overflowing {
      height: 6.8rem;
      border-right: 1px solid #e9ecef;
    }

    .rbc-timeslot-group {
      min-height: 6.4rem;
      border-bottom: 1px solid #e9ecef;
    }

    .rbc-time-content > * + * > * {
      border-left: 1px solid #e9ecef;
    }

    .rbc-day-slot,
    .rbc-time-slot {
      border-top: none;
    }

    .rbc-time-gutter {
      .rbc-timeslot-group {
        width: 70.7812px;
        min-width: 70.7812px;
        max-width: 70.7812px;
        font-size: 1rem;
        border-bottom: none;
        font-weight: 400;
        line-height: 1.6rem;

        .rbc-time-slot {
          display: flex;
          align-items: center;
          justify-content: center;

          .rbc-label {
            padding: 0;
            padding-top: 2rem;
          }
        }
      }
    }

    .rbc-time-header-content {
      .rbc-time-header-cell {
        .rbc-header {
          font-size: 1.3rem;
          line-height: 2.2rem;
          font-weight: 600;
        }
      }
    }
  }

  .rbc-event {
    padding: 0;
    // z-index: 999 !important;
  }
}

.day-view {
  .rbc-time-view {
    .rbc-time-header {
      display: none;
    }

    .rbc-time-gutter {
      .rbc-timeslot-group {
        border-bottom: 1px solid #e9ecef;
      }
    }
  }
}

.custom-event {
  color: #343a40;
  display: flex;
  gap: 0.9rem;
  padding: 0.5rem 0.8rem;
  height: 400px;
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 400;
}

.custom-month-event {
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: #343a40;

  padding: 0.5rem 0.8rem;
}

.event-hover-content {
  background: #262626;
  width: max-content;
  display: flex;
  flex-direction: column;
  color: #adb5bd;
  gap: 0.8rem;
  padding: 0.8rem 1.6rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.2rem;
  border-radius: 2px;

  div {
    display: flex;
    gap: 4.7rem;
    justify-content: space-between;
    :nth-child(2) {
      color: #fff;
    }
  }

  .worker-images {
    display: flex;
    align-items: center;
    position: relative;

    .team-name {
      position: absolute;
      display: flex;
      width: max-content;
      align-items: center;
    }

    .worker-image {
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
  }

  .event-status {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.2rem;
    padding: 0 0.8rem;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    border: 1px solid #dee2e6;
  }
}

.worker-name {
  width: fit-content;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.2rem;
  padding: 0 0.8rem;
  color: #262626;
  background: #fff;
  display: flex;
  border-radius: 0.2rem;
}

.worker-name-tooltip {
  .ant-tooltip-arrow-content::before {
    background: #fff;
  }
}

.event-edit-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 15.6rem;
  width: fit-content;
  background: #f8f9fa;
  border-radius: 0.8rem;
  gap: 0;

  color: #495057;
  font-size: 1.2rem;
  line-height: 2.2rem;
  div {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0.8rem 1.6rem;
    gap: 0.5rem;
    cursor: pointer;
  }
}

.event-edit-tooltip {
  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    border-radius: 0.8rem;
    background: #f8f9fa;
  }
}

.task-modal {
}

.task-popup-wrapper {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 1.2rem;

  max-width: 44.4rem;

  .popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    border-bottom: 0.1rem solid #e9ecef;

    h3 {
      margin: 0;
      padding: 0;
      font-size: 1.6rem;
      font-weight: 700;
      color: #343a40;
    }

    .header-icons {
      display: flex;
      align-items: center;
      gap: 2rem;

      svg {
        cursor: pointer;
      }

      .close {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f8f9fa;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 0.2rem;
        cursor: pointer;
      }
    }
  }

  .popup-details {
    display: flex;
    flex-direction: column;
    margin: 1.6rem 2.4rem 0rem 2.4rem;
    gap: 1.6rem;
    padding-bottom: 2.4rem;
    font-size: 1.2rem;
    line-height: 2.2rem;
  }

  .popup-project-details {
    border-bottom: 0.1rem solid #e9ecef;

    .project-statuses {
      font-weight: 400;
      padding: 0 0.8rem;
      border-radius: 0.4rem;
    }

    .info-row {
      display: flex;
      align-items: center;
      gap: 1.6rem;

      .project-icon {
        width: 2rem;
        height: 2rem;
        background: #3d92ec;
      }

      .project-name-and-status {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .name {
          font-weight: 600;
          color: #212529;
        }
      }

      .row-text {
        color: #606870;
        font-weight: 400;
      }
    }
  }

  .popup-task-details {
    .task-statuses {
      font-weight: 400;
      padding: 0 0.8rem;
      border-radius: 0.4rem;
    }

    .info-row {
      display: flex;
      align-items: center;
      gap: 1.6rem;

      .task-date {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        .date {
          color: #606870;
          background: #f8f9fa;
          border-radius: 0.2rem;
          padding: 0 0.8rem;
        }
      }

      .empty-placeholder {
        width: 2rem;
        height: 2rem;
      }

      .worker-info {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        span {
          font-size: 1.1rem;
        }
        img {
          border-radius: 2rem;
          border: 1px solid #f1f3f5;
        }

        .role {
          font-size: 1rem;
          border-radius: 0.2rem;
          padding: 0rem 0.4rem;
          background: #f8f9fa;
        }
      }
    }
  }
}

.event-tooltip {
  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    border-radius: 1.2rem;
    min-width: 44.4rem;
    color: unset;
    padding: 0;
  }
}

.custom-month-event {
  .ant-modal-wrap {
    position: absolute;
  }
}

.task-popup-modal {
  .ant-modal-content {
    border-radius: 1.2rem;
    border: 0.5px solid #e9ecef;
    box-shadow: none;

    .ant-modal-body {
      padding: 0;
    }
  }
}

@media (max-width: @screen-sm) {
  .task-popup-wrapper {
    .popup-header {
      background: #f1f3f5;
    }
  }
  .task-popup-modal {
    .ant-modal-content {
      border-radius: 0;
      height: calc(100vh - 6.8rem);
    }
  }

  .planning-calendar {
    .rbc-toolbar {
      font-size: 0.9rem;
      flex-wrap: initial;
      justify-content: start;
      gap: 0.5rem;
    }
  }
}
