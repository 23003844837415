.main-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8rem;
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  padding: 0;
  background: #f8f9fa;

  &__full-width {
    ::after,
    ::before {
      content: '';
      position: absolute;
      top: 0;
      height: 8rem;
      background: #f8f9fa;
      border-bottom: 0.1rem solid #e9ecef;
    }

    ::before {
      width: 100%;
      left: -100%;
    }

    ::after {
      right: -2.7rem;
      width: 4rem;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8.6rem;
  width: 100%;
  background: #f8f9fa;

  border-bottom: 0.1rem solid #e9ecef;

  .logo {
    height: 4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .burger-menu-icon {
    display: none;
  }
}

.back-link {
  &--mobile,
  &--desktop {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding-left: 2.7rem;
    cursor: pointer;
  }

  &--mobile {
    display: none;
  }

  &__title {
    color: #606870;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  svg {
    background: #f1f3f5;
    border-radius: 100%;
    height: 2.4rem;
    width: 2.4rem;
    padding: 0.4rem;
  }
}

.burger-menu {
  display: none;
}

@media (max-width: @screen-lg) {
  .header {
    position: fixed;
  }
}

@media (max-width: @screen-sm) {
  .burger-menu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.4s;
    text-align: center;
    display: block;
    padding-top: 4.4rem;

    &__close-btn {
      padding-right: 1.3rem;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }

    &__links-container {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      align-items: flex-start;
      padding-left: 2.9rem;
      margin-top: 9rem;
      width: 30rem;

      a {
        display: flex;
        align-items: center;
        gap: 1.8rem;
        color: #343a40;
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }

  .main-header-wrapper {
    ::before,
    ::after {
      display: none;
    }

    .header {
      background-color: white;
      height: 8.6rem;

      ::before {
        background-color: white;
      }

      .logo {
        height: 2.5rem;
        top: 100%;
        transform: translate(-50%, -3.6rem);
      }

      .burger-menu-icon {
        display: flex;
        position: absolute;
        bottom: 0;
        right: 1.5rem;
        align-items: center;
        justify-content: center;
        bottom: 1.1rem;
      }
    }

    .back-link {
      padding-left: 0.5rem;
      align-self: flex-end;
      padding-bottom: 1.3rem;
      height: 2.4rem;
      margin-bottom: 1.3rem;

      &--mobile {
        display: flex;
        padding-left: 0;
      }

      &--desktop {
        display: none;
      }

      svg {
        background-color: transparent;
        padding: 0;
      }
    }
  }
}
