.ant-collapse {
  border: none;
  background-color: #e8e8e8;
}
.ant-collapse-content-box {
  border-left: #d9d9d9 1px solid;
  border-right: #d9d9d9 1px solid;
  border-bottom: #d9d9d9 1px solid;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.ant-collapse-item {
  border-radius: 24px !important;
  background-color: @main-blue;
  margin-top: 15px;
}
.ant-collapse-item:first-child {
  margin-top: 0px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: white;
}

.ant-collapse-icon {
  color: #eff9ff;
}

.ant-notification-notice-error {
  border-bottom: solid 6px #ff4d4f;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ant-notification-notice-success {
  border-bottom: solid 6px #73d13d;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ant-notification-notice-warning {
  border-bottom: solid 6px #ffa940;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ant-collapse-content {
  border-bottom-left-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}
.wrapperContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
