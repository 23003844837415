.project__creation {
  margin-top: 3.2rem;
  min-height: 100vh;
  width: 100%;
  background-color: #f8f9fa;
  position: relative;
  padding-bottom: 7rem;
  padding-left: 3.8rem;

  .project__creation__form__container {
    .form__section__container {
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
      border-radius: 24px;
      padding: 3.2rem 4rem;
      position: relative;
    }

    ::placeholder {
      color: #868e96;
      opacity: 1;
    }

    .ant-form-item {
      margin-bottom: 0;
      display: flex;
      flex-direction: column;

      input {
        font-size: 1.6rem;
      }
    }

    .ant-form-item-label > label::after {
      display: none;
    }

    .ant-select-selector {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border-color: transparent !important;
    }

    .ant-select-selector {
      height: 4.8rem !important;
    }

    .ant-select-arrow {
      color: #868e96;
      font-size: 1.4rem;
      height: 1.5rem;
    }

    label {
      color: #212529;
      font-weight: 400;
      font-size: 1.4rem !important;
      width: 100%;
    }

    .ant-radio-wrapper-checked {
      background-color: #eff9ff;
      border: 1px solid #1f73e2 !important;
    }

    .ant-radio-group {
      display: flex;
      gap: 2.4rem;

      label {
        width: 36.4rem;
        height: 7rem;
        border-radius: 8px;
        border: 1px solid #dee2e6;
        padding: 2.7rem 1.6rem;
        padding-top: 2.4rem;

        .ant-radio-inner {
          background-color: #f8f9fa !important;
          top: 0.5px;
        }

        .type__radio__option {
          display: flex;
          gap: 23rem;
          align-items: center;
        }
      }

      .ant-radio-checked .ant-radio-inner {
        border-color: #1f73e2;
      }

      .ant-radio-checked .ant-radio-inner::after {
        background: #1f73e2;
      }
    }

    .project__details__inputs {
      .annual_consumption_input {
        display: flex;
        height: 12rem;
      }
    }

    .project__creation__form__item {
      display: flex;
      min-height: 11rem;

      .ant-input {
        border: 1px solid #dee2e6;
        border-radius: 8px;
        height: 4.8rem;
      }
    }

    .project__creation__form__item__disabled {
      label {
        color: #bcbebf;
      }

      ::placeholder {
        color: #bcbebf !important;
      }

      .ant-input {
        border: 1px solid #f5f6f7;
      }

      input {
        background-color: white;
      }
    }

    .client__information__container {
      .contact__section,
      .address__section,
      .project__details__container {
        margin-top: 3.2rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 2.4rem;
        row-gap: 0.4rem;
      }

      .contact__section {
        .ant-picker {
          height: 4.8rem;

          .ant-picker-suffix {
            display: none;
          }
        }
      }
    }

    textarea {
      resize: none;
      height: 7.9rem !important;
      font-size: 1.6rem;
    }

    .project__details__container {
      margin-top: 6rem;

      .section__title {
        margin-bottom: 2.4rem;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }

      .form__section__container {
        margin-top: 3.2rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 2.4rem;
        row-gap: 0.4rem;
        padding: 4rem;
      }

      ::placeholder {
        color: #868e96;
        opacity: 0.6;
      }

      .ant-input {
        height: 4.8rem;
      }

      .project__details__inputs {
        margin-top: 0;
      }
    }

    .section__title {
      font-weight: 600;
      font-size: 2.4rem;
      text-align: left;
    }

    .action__button__group {
      display: flex;
      align-items: center;
      justify-content: end;
      margin-top: 3rem;
      gap: 2.4rem;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        padding: 1.2rem 2.4rem;
        border-radius: 12px;
        height: 4.8rem;
        font-weight: 600;
        color: white;
      }

      .cancel__btn {
        background: #eff9ff;
        color: #3d92ec;
      }
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    position: fixed;
    height: 100vh !important;
    width: 100vw;
    max-height: none;
  }

  .ant-form-item-explain-error {
    padding-left: 0 !important;
  }

  .error {
    label {
      color: @warning-red !important;
    }

    .ant-input,
    .ant-picker {
      border: 1px solid @warning-red !important;
    }
  }
}

.project-header-wrapper {
  width: 100%;
}

.nav_back_wrapper {
  color: #606870;
  display: flex;
  gap: 0.8rem;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f3f5;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 100px;
  }
  button {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 100%;
    cursor: pointer;
    background: #f8f9fa;
    border: none;
  }

  p {
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 0;
  }
}

.project_details_selector {
  height: 10.2rem;
}

.project__details__inputs {
  .ant-select-selector {
    justify-content: start !important;
  }

  .ant-select-selection-item {
    max-width: 33rem !important;
  }
}

.selector_error {
  .ant-select-selector {
    border: 1px solid @warning-red !important;
  }

  label,
  .ant-select-arrow {
    color: @warning-red !important;
  }
}

@media (max-width: 1440px) {
  .project__details__inputs {
    .ant-select-selection-item {
      max-width: 21rem !important;
    }
  }
}

@media (max-width: @screen-lg) {
  .project__creation {
    padding: 0 2.4rem 7rem 2.4rem;

    .ant-radio-group {
      display: flex;
      gap: 2.4rem;

      label {
        width: 50% !important;
        justify-content: space-between;

        .type__radio__option {
          justify-content: space-between;
          gap: 0 !important;
        }

        span:first-child {
          width: 1.7rem !important;
          height: 1.6rem !important;
        }

        span:last-child {
          width: 100%;
        }
      }
    }

    .form__section__container {
      padding: 2.4rem !important;
    }

    .project__details__inputs,
    .contact__section,
    .address__section,
    .project__details__container {
      margin-top: 2.4rem !important;
      grid-template-columns: 1fr 1fr !important;
      column-gap: 1rem !important;
    }

    .project__details__inputs {
      margin-top: 1.6rem !important;
    }
  }

  .project__details__inputs {
    .ant-select-selection-item {
      max-width: 24rem !important;
    }
  }
}

@media (max-width: @screen-sm) {
  .project__header__container {
    padding-top: 4.3rem;

    .back__link__text {
      display: none;
    }
  }

  .project__creation {
    padding: 0 1.8rem 4rem 1.8rem;

    .section__title {
      font-size: 2rem !important;
    }

    .ant-radio-group {
      flex-direction: column;

      label {
        width: 100% !important;
      }
    }

    .project__creation__form__container .project__creation__form__item {
      flex-direction: column;
      height: 11rem;
    }

    .project_details_selector {
      height: 11rem;
    }

    .project__details__inputs,
    .contact__section,
    .address__section,
    .project__details__container {
      grid-template-columns: 1fr !important;
      row-gap: 2.4rem !important;
    }

    .form__section__container {
      padding: 2.4rem 1.6rem !important;
    }

    .action__button__group {
      margin-top: 2rem;
    }

    .ant-form .ant-form-item .ant-form-item-label,
    .ant-form .ant-form-item .ant-form-item-control {
      flex: initial;
    }
  }

  .nav_back_wrapper {
    p {
      display: none;
    }
  }
}
