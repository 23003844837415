.checkscreen {
  width: 100%;
  background-color: white;
  border-radius: 24px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  flex-direction: column;
  position: relative;

  .product_title {
    display: flex;
    font-weight: bold;
    align-items: center;
  }

  &__footer {
    padding-top: 4rem;
    width: 100%;
  }

  .type-desctiption {
    margin-top: 6rem;
    width: 100%;
    flex-direction: column;

    .price_details_checkbox {
      margin-bottom: 2rem;
    }

    .list {
      width: 100%;
      flex-direction: column;

      &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 3rem;
        .leftside {
          width: 100%;
          &__title {
            font-size: 1.2rem;
            font-weight: 600;
            display: flex;
            font-weight: bold;
            align-items: center;
            ::first-letter {
              text-transform: capitalize;
            }
          }
          &__subtitle {
            color: #868e96;
            font-style: italic;
            display: flex;
            align-items: center;
            max-width: 30%;
            word-break: break-all;
          }
          &__item {
            display: flex;
            justify-content: space-between;
            margin-top: 0.5rem;
            .ant-input-number-input,
            .ant-input-number {
              width: 100%;
            }
          }
          .list_wrapper {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 70%;
            .ind_list_wrapper {
              display: flex;
              justify-content: center;
            }
            .list_title_mobile {
              font-size: 1.3rem;
              display: flex;
              font-weight: 400;
              align-items: center;
              display: none;
            }

            .quantity {
              width: 15%;
            }
            .quantity_hide_purchase_price {
              width: 30%;
            }

            .purchase_price {
              width: 30%;
              padding-left: 1rem;
              padding-right: 1rem;
              display: flex;
              justify-self: flex-start;
              align-items: center;
            }
          }
          .list {
            display: flex;
            justify-self: flex-start;
            align-items: center;
            padding-left: 1rem;
            padding-right: 1rem;
          }

          .quantity {
            width: 15%;
          }

          .quantity_hide_purchase_price {
            width: 30%;
          }

          .selling_price {
            width: 100%;
            display: flex;
            input[type='number']::-webkit-inner-spin-button,
            input[type='number']::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
          .list_price_input {
            width: 60%;
            font-weight: bold;
            display: flex;

            .price_wrapper {
              position: relative;
              margin-left: 0.5rem;
              margin-right: 0.5rem;

              input[type='number']::-webkit-inner-spin-button,
              input[type='number']::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              .selling_price_input {
                display: flex;
                text-align: right;
                border-radius: 0.8rem;
                .ant-input-number-input {
                  text-align: right;
                  color: rgba(0, 0, 0, 0.8);
                }
              }
              .currency {
                position: absolute;
                z-index: 1;
                top: 50%;
                transform: translateY(-50%);
                left: 0.75rem;
                font-weight: 200;
                color: rgba(0, 0, 0, 0.85);
              }
            }

            .ant-input[disabled] {
              color: rgba(0, 0, 0, 1);
              font-weight: 400;
            }
          }
          .hide_purchase_price {
            width: 100%;
          }
        }

        .rightside {
          font-style: italic;
        }
      }
    }
  }
  .preffered {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 6rem;

    &__input {
      margin-top: 2.4rem;
    }
  }

  .check_header_wrapper {
    font-size: 1.4rem;
    font-weight: bold;

    display: flex;
    justify-content: space-between;
    .price_title_wrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 70%;
    }
    .price_title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;
    }
    .quantity {
      width: 15%;
    }
  }
  .total_prices {
    margin-top: 2.5rem;
    .ind_total_price {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      .ant-input[disabled] {
        color: rgba(0, 0, 0, 1);
        font-weight: 400;
      }

      .total_title {
        display: flex;
        align-items: center;
        width: 30%;
        max-width: 30%;
      }

      .total_value {
        display: flex;
        text-align: right;
        align-items: center;
        width: 65%;
        max-width: 65%;
      }

      .discount {
        position: relative;
        &__input {
          display: flex;
          text-align: right;

          width: 15rem;
          padding-right: 2.2rem;
          border-radius: 0.8rem;
        }
        &__percent_sign {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(-50%, -50%);
        }
      }

      .correction_input {
        display: flex;
        text-align: right;

        width: 15rem;
        border-radius: 0.8rem;
        .ant-input-number-input {
          text-align: right;
        }
      }
    }
  }
}

.signature {
  width: 100%;
  flex-direction: column;
  margin-top: 5rem;

  &__button {
    margin-top: 2.4rem;

    &__container {
      display: flex;
      gap: 1rem;
    }
  }
}

.date-picker-suffix-error {
  color: @warning-red !important;
}

@media (max-width: @screen-lg) {
  .checkscreen {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    padding: 3rem;
    .signature {
      height: 100%;
      &__button {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
      }
    }

    .preffered__input {
      flex-direction: column;
    }

    .type-desctiption {
      .list {
        &__item {
          .leftside {
            &__subtitle {
              max-width: 100%;
              margin-top: 2rem;
            }
            &__item {
              flex-direction: column;
            }
            .list_wrapper {
              flex-direction: column;
              width: 100%;
              .ind_list_wrapper {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                margin-top: 1rem;
                .list_title_mobile {
                  display: block;
                }
              }
              .purchase_price {
                padding: 0;
              }
            }

            .list_price_input {
              width: 100%;
              flex-direction: column;
              .price_wrapper {
                width: 30%;
                margin: 0;
              }
            }
          }

          .rightside {
            font-style: italic;
          }
        }
      }
    }

    .total_prices {
      .ind_total_price {
        flex-direction: column;
        gap: 1rem;

        .total_title {
          width: 100%;
          max-width: 100%;
        }

        .total_value {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .check_header_wrapper {
      .price_title_wrapper {
        display: none;
      }
    }
  }
}

@media (max-width: @screen-sm) {
  .checkscreen {
    .signature {
      &__button {
        flex-direction: column;
        justify-content: start;

        button {
          margin-right: auto;
          width: 19rem;
        }
      }
    }
  }
}
