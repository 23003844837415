.manage {
  min-height: 100vh;

  &__container {
    padding: 2.1rem 5.6rem 0 5.6rem;
    height: calc(100vh - 8rem);
    display: flex;
    flex-direction: column;
    gap: 3.5rem;

    &__title {
      text-align: center;
      color: #212529;
      font-size: 2.4rem;
      font-weight: 600;
    }

    &__pages-sections--mobile {
      display: none;
    }

    &__pages-sections--desktop {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 3.2rem;
      height: 100%;
      padding-bottom: 7.2rem;

      .coming-soon {
        height: 4.8rem;
        padding: 1rem;
        color: #343a40;
        font-size: 1.6rem;
        font-weight: 500;
        border-radius: 4px;
        background: #ffd666;
        text-align: center;
      }

      &__card {
        padding: 5.5rem 2rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 8px;

        &__section-icon {
          display: flex;
          justify-content: center;
        }

        &__title {
          text-align: center;
          margin-top: 4rem;
          color: #495057;
          font-size: 1.8rem;
          font-weight: 600;
        }

        &__links {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          &__link {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__title {
              transition: all 0.2s ease-in-out;
              font-size: 1.6rem;
              font-weight: 400;
              margin-bottom: 0;
              color: #1f73e2;
            }

            &:hover {
              p {
                color: #0a53d4;
                font-weight: 500;
              }

              svg {
                stroke: #0a53d4;
              }
            }
          }
        }
      }
    }
  }
}

.mounting-system-inputs {
  .material {
    padding-bottom: 0;
    flex-direction: row;

    label {
      padding-bottom: 3rem;
    }
  }
}

.manageScreensLayout {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  margin-bottom: 10rem;

  &__title {
    font-size: 2.4rem;
    color: #212529;
    margin-top: 4.4rem;
  }

  &__content {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__content:last-child {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .contentheader {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 4rem;
    margin-bottom: 1.6rem;
    gap: 1.6rem;
  }
  .contentFooter {
    width: 100%;
    display: flex;
    margin-top: 1rem;
    gap: 1.6rem;
  }

  .addConverterModal {
    margin-top: 1rem;
  }

  .synced-result {
    margin: 0;

    .ant-result-icon {
      margin-bottom: 0;
      .anticon {
        font-size: 3rem;
      }
    }
  }
  .ant-result {
    padding: 0;
  }

  &__document-library {
    .ant-table-row {
      cursor: default;
    }
  }
}

.user__form {
  .ant-col-20 {
    flex: 0 0 80%;
  }

  .ant-col-4 {
    flex: 0 0 20%;
    max-width: inherit;
  }
}

.company__form__modal {
  .ant-col-7 {
    flex: 0 0 30%;
  }

  .ant-upload-list-item-name {
    width: 20rem;
  }

  .ant-col-20 {
    flex: 0 0 70%;
    max-width: inherit;
  }

  .solar__planning__checkbox {
    margin-left: 7%;

    label {
      span {
        flex: 1;
      }

      span:nth-child(1) {
        order: 2;
      }

      .ant-checkbox-checked:active,
      .ant-checkbox-checked::after {
        border: none !important;
      }

      .ant-checkbox,
      .ant-checkbox-checked {
        border: none !important;
      }

      .ant-checkbox + span {
        width: 29rem;
        text-align: right;
      }

      span:nth-child(2) {
        order: 1;
      }
    }
  }
}

@media (max-width: 1280px) {
  .manage {
    &__container {
      padding: 2.8rem 3.6rem 0 3.6rem;
      gap: 2.8rem;

      &__pages-sections--desktop {
        padding-bottom: 4rem;
        column-gap: 2rem;

        &__card {
          gap: 2rem;
          padding: 2.5rem 1.5rem;

          &__title {
            margin-top: 2rem;
          }

          &__links {
            gap: 0.8rem;
          }

          &__section-icon {
            svg {
              width: 7rem;
              height: 7rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: @screen-lg) {
  .manage {
    &__container {
      padding: 2.8rem 2rem 0 2rem;

      &__pages-sections--desktop {
        padding-bottom: 12rem;
        column-gap: 2rem;

        .coming-soon {
          height: 4rem;
          padding: 0.8rem;
          font-size: 1.4rem;
        }

        &__card {
          padding-left: 1rem;
          padding-right: 1rem;

          &__title {
            font-size: 1.6rem;
          }

          &__links {
            &__link {
              &__title {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }

  .manageScreensLayout {
    margin-bottom: 0;

    .companies {
      padding-bottom: 10rem;
    }

    &__document-library {
      padding-bottom: 8rem;
    }

    .contentFooter {
      flex-grow: 1;
      align-items: flex-end;
      padding-bottom: calc(@footer-height + 2.4rem);
    }

    .contentFooter_activitiesTable {
      padding-bottom: 0;
    }
    .contentheader {
      gap: 2.3rem;
      &__search {
        width: 100%;
      }
    }
  }
  .users_table {
    padding-bottom: calc(@footer-height-mobile + 2rem);
  }

  .user__form {
    margin-bottom: 3rem;

    .ant-col-4 {
      max-width: 100%;
    }

    .ant-form-item {
      flex-direction: column !important;

      .ant-col-20 {
        max-width: 100%;
      }

      .ant-form-item-label {
        text-align: left;
      }
    }
  }
}

.margin-b-1 {
  margin-bottom: 0.4rem;
}

@media (max-width: 750px) {
  .manage {
    &__container {
      padding-top: 1.7rem;
      gap: 3.8rem;
      padding: 1.7rem 1.8rem 0 1.8rem;

      &__title {
        font-size: 2rem;
      }

      &__pages-sections--desktop {
        display: none;
      }

      &__pages-sections--mobile {
        display: flex;
        flex-direction: column;
        gap: 1.4rem;

        .ant-collapse-content {
          border: none;

          &-box {
            padding: 0;
            border: none;
          }
        }

        &__accordion-container {
          display: flex;
          flex-direction: column;
          background-color: white;
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;

          .coming-soon--mobile,
          &__link {
            color: #495057;
            font-size: 1.6rem;
            font-weight: 500;
            padding: 0 1.6rem;
            height: 4.6rem;
            display: flex;
            align-items: center;
            border-top: 1px solid #f1f3f5;
            border-bottom: 1px solid #f1f3f5;
          }

          .coming-soon--mobile {
            color: #868e96;
          }

          :last-child {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            border-bottom: none;
          }
        }

        .ant-collapse {
          background-color: transparent;

          &-item {
            border: none;
          }

          &-header {
            height: 5.4rem;
            background-color: #3d92ec;
            align-items: center !important;
            border-radius: 8px !important;
            color: #fff;
            font-size: 1.6rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media (max-width: @screen-sm) {
  .manage {
    margin-bottom: calc(3.3rem + @footer-height-mobile);

    .content {
      &__box {
        height: 5.8rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }

  .companies_table {
    padding-bottom: calc(@footer-height-mobile + 2rem);
  }

  .manageScreensLayout {
    .companies {
      padding-bottom: 0;
    }

    .contentheader {
      flex-direction: column;
      gap: 2rem;
      margin-top: 4rem;
    }
    .contentFooter {
      margin-top: 2rem;
      flex-direction: column;
      gap: 2rem;
      width: 100%;
      padding-bottom: calc(@footer-height-mobile + 2rem);

      &__child {
        width: 100%;
      }
    }
    .contentFooter_activitiesTable {
      padding-bottom: 0;
    }
  }

  .user__form {
    margin-bottom: 0;
  }

  .company__form__modal {
    .ant-col-7 {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: inherit;
    }

    .ant-col-20 {
      flex: 0 0 100%;
      max-width: inherit;
    }

    .solar__planning__checkbox {
      margin-left: 0;
    }
  }
}
