.task_schedule_drop_down{
  z-index: 50000;
}

.custom-modal {
  padding: 0 !important;
  border-radius: 5rem;
  width: 92% !important;
  margin: 0 auto;
  height: 80%;
  transition: 0.3s ease-in-out;
  font-family: open-sans, serif !important;

  .details_and_activity_switcher {
    display: none;
  }

  .ant-modal-content {
    border-radius: 2rem;
    height: 100%;
  }

  .ant-modal-body {
    height: 100%;
    position: relative;
    overflow: hidden;

    .spinner-center {
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
    }
  }

  .ant-modal-close-x {
    display: none;
  }

  .project-view-modal-header {
    padding: 1.8rem 2.4rem 1.8rem 2.8rem;
    border-bottom: solid 0.1rem #dee2e6;

    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.7rem;

      .header-btn {
        padding: 0 2rem 0 2rem;
        border-radius: 0.7rem;
      }

      .condense-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f1f3f5;
        padding: 0.4rem 1.2rem;
        border-radius: 0.6rem;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 2.4rem;
        gap: 0.6rem;
        border: 0.1rem solid rgba(0, 0, 0, 0);
        cursor: pointer;

        &:hover {
          border: 0.1rem solid var(--gray-gray-5, #adb5bd);
        }
      }

      .modal-corner-btns {
        margin-left: 2rem;

        button {
          background: none;
          border: none;
          cursor: pointer;
          color: #212529;
          font-size: 1.6rem;

          :hover {
            color: #606870;
          }
        }
      }
    }
  }

  .project-view-modal-container {
    display: flex;
    height: 100%;
  }

  .project-view-modal-sidebar {
    flex: 19%;
    border-right: 0.1rem #dee2e6 solid;
    padding-top: 1.5rem;
    font-size: 1.3rem;

    button {
      width: 100%;
      padding: 1rem 0 1rem 2rem;
      text-align: start;
      background: none;
      cursor: pointer;
      color: #606870;
      border: 0.3rem solid rgba(0, 0, 0, 0);
      font-weight: 400;
      line-height: 2.4rem;
      font-size: 1.3rem;

      &:hover {
        border-left: 0.3rem solid #3d92ec;
        background: #f8f9fa;
        color: #3d92ec;
      }
    }

    .active {
      border-left: 0.3rem solid #3d92ec;
      background: #f8f9fa;
      color: #3d92ec;
      font-weight: 600;
    }
  }

  .project-view-modal-content {
    flex: 52%;
    height: calc(100% - 9.6rem);
    border-right: 0.1rem #dee2e6 solid;
    margin-top: 1.5rem;
    justify-content: center;
    overflow-y: auto;

    .project_details_wrap {
      .project_details_container {
        border: 0.1rem #e9ecef solid;
        border-radius: 0.4rem;
        width: 90%;
        margin: 0 auto;

        .project_details_header {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem 0;
          background: #f8f9fa;
          border-bottom: 0.1rem #e9ecef solid;
          position: relative;
          border-top: 0.4rem;

          h4 {
            color: #495057;
            margin-bottom: 0 !important;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 600;
            line-height: 2.2rem;
          }

          button {
            position: absolute;
            right: 1.5rem;
            font-size: 1rem;
            font-weight: 600;
            padding: 0.3rem 0.8rem 0.3rem 0.8rem;
            background-color: rgb(0 110 247);
            color: white;
            border: none;
            border-radius: 0.2rem;
            cursor: pointer;
            line-height: 1.3rem;

            &:disabled {
              border: 0.1rem solid #dee2e6;
              background-color: #ffffff;
              color: #adb5bd;
              cursor: not-allowed;
            }
          }
        }

        .project_details_content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          font-size: 1.2rem;

          .project_details_input_group {
            width: 50%;
            padding: 1.5rem;
            position: relative;

            label {
              width: 100%;
              font-weight: 400;
              font-size: 1rem;
              color: #606870;
            }

            .project_details_input {
              border: 0.1rem solid #dee2e6;
              border-radius: 0.4rem;
              padding: 0.4rem 0.6rem;
              color: #606870;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;

              input {
                width: 95%;
                color: #606870;
                border: none;
                background: none;
                cursor: pointer;

                &:focus {
                  outline: none;
                }
              }

              .input_full {
                width: 100% !important;
              }

              .edit_icon {
                width: 1.25rem;
                height: 1.25rem;
                cursor: pointer;
              }
            }

            .select_header {
              width: 100%;
              height: 3.2rem;
              border: 0.1rem solid #dee2e6;
              border-radius: 0.4rem;
              padding: 0.6rem 0.8rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;

              .down_outline {
                transform: rotate(0turn);
                transition: transform 0.3s ease;
              }

              .open_down_outlined {
                transform: rotate(0.5turn);
              }

              svg {
                width: 1.2rem;
                height: 1.2rem;
              }

              .selected_header_option {
                padding: 0.3rem 0.8rem 0.3rem 0.8rem;
                border-radius: 0.4rem;
                cursor: pointer;
                font-size: 1.2rem;
                font-weight: 400;
                white-space: nowrap;
              }

              .select_header_placeholder {
                font-size: 1.2rem;
                line-height: 1.6rem;
                font-weight: 400;
                color: #606870;
                white-space: nowrap;
              }
            }

            .select_background {
              position: fixed;
              width: 100vw;
              height: 100vh;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              z-index: 15;
            }
            .select_options {
              z-index: 35;
              position: absolute;
              width: 80%;
              background: white;
              max-height: 22rem;
              border-radius: 0.8rem;
              overflow-y: auto;
              box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
              padding: 0 1.6rem;

              .select_option {
                display: flex;
                width: fit-content;
                padding: 1.2rem 0;

                > div {
                  padding: 0.3rem 0.8rem 0.3rem 0.8rem;
                  border-radius: 0.4rem;
                  cursor: pointer;
                  font-size: 1.2rem;
                  font-weight: 400;

                  &:hover {
                    background: #ece9e9;
                  }
                }
              }
            }
          }

          .project_details_multiselect_group {
            width: 100%;
            padding: 1.5rem;
            position: relative;

            > .multiselect_background {
              position: fixed;
              width: 100vw;
              height: 100vh;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              z-index: 15;
            }

            > label {
              width: 100%;
              font-weight: 400;
              font-size: 1rem;
              color: #606870;
            }

            > .multiselect_field {
              width: 100%;
              border: 0.1rem solid #dee2e6;
              border-radius: 0.4rem;
              padding: 0.6rem 0.8rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              position: relative;
              z-index: 30;

              .multiselect_header_placeholder {
                font-size: 1.2rem;
                line-height: 1.6rem;
                font-weight: 400;
                color: #606870;
                white-space: nowrap;
              }

              .multiselect_header_placeholder_selected_options {
                padding: 0 0.4rem;
                display: flex;
                flex-wrap: wrap;
                gap: .4rem;

                .multiselect_header_placeholder_selected_option {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0 0.4rem;
                  gap: 0.4rem;
                  border-radius: 0.4rem;
                  border: 1px solid #DEE2E6;

                  > span {
                    color: #212529;
                    font-size: 1.2rem;
                    font-weight: 400;
                    line-height: 2.2rem;
                  }

                  > img {
                    width: 1.6rem;
                    height: 1.6rem;
                  }

                  svg {
                    color: #212529;
                  }
                }
              }

              .multiselect_down_outline {
                transform: rotate(0turn);
                transition: transform 0.3s ease;
              }

              .multiselect_open_down_outlined {
                transform: rotate(0.5turn);
              }

              svg {
                width: 1.2rem;
                height: 1.2rem;
              }
            }

            .multiselect_options {
              z-index: 20;
              position: absolute;
              width: 80%;
              background: white;
              max-height: 30rem;
              border-radius: 0.8rem;
              box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);

              .multiselect_options_header {
                padding: 1.2rem 1.6rem;
                font-size: 1.2rem;
                font-weight: 600;
                line-height: 22px;
                border-bottom: 1px solid #F1F3F5;
              }

              .multiselect_options_search {
                padding: 0 1.6rem;
                display: flex;
                justify-content: start;
                align-items: center;
                border-bottom: 1px solid #F1F3F5;

                svg {
                  width: 16px;
                  height: 16px;
                }
                input {
                  background: none;
                  border: none;
                  padding: .7rem 0.8rem;
                  width: 80%;
                  outline: none;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 22px;
                }
              }

              .multiselect_options_container {
                overflow-y: auto;
                height: 16rem;
                border-bottom: 1px solid #F1F3F5;
                width: 100%;

                >div {
                  width: 100%;
                  height: 4rem;
                  display: flex;
                  justify-content: start;
                  align-items: center;

                  label {
                    padding: 1.2rem 1.6rem;
                    width: 100%;
                    cursor: pointer;

                    &:hover {
                      background: #f7f7f8;
                    }

                    span {
                      margin-left: 0.8rem;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px;
                      color: #343A40;
                    }
                  }
                }
              }

              .multiselect_options_footer{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1.2rem 1.6rem;

                .multiselect_reset_btn {
                  color: #868E96;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                  border: none;
                  background: none;
                  cursor: pointer;

                  &:hover {
                    color: #595a60;
                  }
                }

                .right_btns {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 1.6rem;

                  .multiselect_cancel_btn {
                    color: #3D92EC;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    border: none;
                    background: none;
                    cursor: pointer;

                    &:hover {
                      color: #2a77cb;
                    }
                  }

                  .multiselect_save_btn {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    padding: 0.4rem 1rem;
                    border: none;
                    border-radius: 0.8rem;
                    background: #3D92EC;
                    cursor: pointer;

                    &:hover {
                      background: #2a77cb;
                    }
                  }
                }
              }
            }
          }

          .project_details_planner_input_group {
            width: 100%;
            padding: 1.5rem;
            position: relative;

            label {
              width: 100%;
              font-weight: 400;
              font-size: 1rem;
              color: #606870;
            }

            select {
              width: 100%;
              border: 0.1rem solid #dee2e6;
              border-radius: 0.4rem;
              padding: 0.6rem 0.6rem;

              &:focus {
                outline: none;
              }
            }

            .select_header {
              width: 100%;
              height: 3.2rem;
              border: 0.1rem solid #dee2e6;
              border-radius: 0.4rem;
              padding: 0.6rem 0.8rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;

              .down_outline {
                transform: rotate(0turn);
                transition: transform 0.3s ease;
              }

              .open_down_outlined {
                transform: rotate(0.5turn);
              }

              svg {
                width: 1.2rem;
                height: 1.2rem;
              }

              .selected_header_option {
                padding: 0.3rem 0.8rem 0.3rem 0.8rem;
                border-radius: 0.4rem;
                cursor: pointer;
                font-size: 1.2rem;
                font-weight: 400;
                white-space: nowrap;
              }

              .select_header_placeholder {
                font-size: 1.2rem;
                line-height: 1.6rem;
                font-weight: 400;
                color: #606870;
                white-space: nowrap;
              }
            }

            .select_background {
              position: fixed;
              width: 100vw;
              height: 100vh;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              z-index: 15;
            }
            .select_options {
              z-index: 35;
              position: absolute;
              width: 80%;
              background: white;
              max-height: 22rem;
              border-radius: 0.8rem;
              overflow-y: auto;
              box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
              padding: 0 1.6rem;

              .select_option {
                display: flex;
                width: fit-content;
                padding: 1.2rem 0;

                > div {
                  padding: 0.3rem 0.8rem 0.3rem 0.8rem;
                  border-radius: 0.4rem;
                  cursor: pointer;
                  font-size: 1.2rem;
                  font-weight: 400;

                  &:hover {
                    background: #ece9e9;
                  }
                }
              }
            }
          }
        }
      }

      .tasks_wrap {
        margin: 3rem auto;
        width: 90%;

        .task_container {
          margin: 1.5rem 0;
          border: 0.1rem #e9ecef solid;
          border-radius: 1rem;

          .task_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 1.5rem;
            background: #f8f9fa;
            border-bottom: 0.1rem #e9ecef solid;

            .task_header_title {
              display: flex;
              align-items: center;
              gap: 0.8rem;
              cursor: pointer;

              .down_outline {
                transform: rotate(0turn);
                transition: transform 0.3s ease;
              }

              .open_down_outlined {
                transform: rotate(0.5turn);
              }

              svg {
                width: 1.2rem;
                height: 1.2rem;
              }
            }

            .task_header_btn_group {
              display: flex;
              justify-content: center;
              align-items: center;

              .task_header_save_btn {
                font-size: 1rem;
                font-weight: 600;
                padding: 0.3rem 0.8rem 0.3rem 0.8rem;
                background-color: rgb(0 110 247);
                color: white;
                border: none;
                border-radius: 0.2rem;
                cursor: pointer;
                line-height: 1.3rem;
                margin-right: 0.75rem;

                &:disabled {
                  border: 0.1rem solid #dee2e6;
                  background-color: #ffffff;
                  color: #adb5bd;
                  cursor: not-allowed;
                }
              }

              .btn_separator {
                width: 0.1rem;
                height: 1.5rem;
                background-color: #dee2e6;
              }

              .task_header_delete_btn {
                border: none;
                background: none;
                margin-left: 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 2.2rem;
                padding: 0.3rem 0;
                cursor: pointer;
                color: #495057;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          .is_close {
            max-height: 0 !important;
            overflow: hidden;
          }

          .task_content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-size: 1.2rem;
            transition: max-height 2s ease;

            .date_field {
              z-index: 5000;
            }

            > .ant-form-item {
              margin-bottom: 0!important;
            }

            .task_date_field {
              width: 50%;
              padding: 1.5rem;
              position: relative;
              flex-direction: column;
              padding-top: 1.8rem;

              label {
                display: flex;
                font-weight: 400;
                font-size: 1rem;
                color: #606870;
                height: auto!important;

                &:after {
                  content: none;
                }
              }

              input {
                color: #606870!important;
                &::placeholder {
                  color: #606870!important;
                }
              }

              .ant-picker {
                border: 0.1rem solid #dee2e6!important;
                border-radius: 0.4rem!important;
                padding: 0.4rem 0.6rem;
                color: #606870;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                height: 3.1rem;
              }
            }

            .project_details_input_group {
              width: 50%;
              padding: 1.5rem;
              position: relative;

              label {
                width: 100%;
                font-weight: 400;
                font-size: 1rem;
                color: #606870;
              }

              .project_details_input {
                border: 0.1rem solid #dee2e6;
                border-radius: 0.4rem;
                padding: 0.4rem 0.6rem;
                color: #606870;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                height: 3.1rem;

                .start_finish_time_placeholder {
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  gap: 0.4rem;
                }

                .time_placeholder {
                  padding: 0.2rem 0.4rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #F1F3F5;
                  color: #212529;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16px;
                  text-transform: lowercase;
                  border-radius: .4rem;
                  cursor: pointer;
                  width: 6.1rem;
                  white-space: nowrap;
                }

                .time_placeholder_active {
                  background: #FFF;
                  border: 2px solid #63AFF4;
                }

                input {
                  width: 95%;
                  color: #606870;
                  border: none;
                  background: none;
                  cursor: pointer;

                  &:focus {
                    outline: none;
                  }
                }

                .input_full {
                  width: 100% !important;
                }

                .edit_icon {
                  width: 1.25rem;
                  height: 1.25rem;
                  cursor: pointer;
                }
              }

              .select_header {
                width: 100%;
                height: 3.2rem;
                border: 0.1rem solid #dee2e6;
                border-radius: 0.4rem;
                padding: 0.6rem 0.8rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                .down_outline {
                  transform: rotate(0turn);
                  transition: transform 0.3s ease;
                }

                .open_down_outlined {
                  transform: rotate(0.5turn);
                }

                svg {
                  width: 1.2rem;
                  height: 1.2rem;
                }

                .selected_header_option {
                  padding: 0.3rem 0.8rem 0.3rem 0.8rem;
                  border-radius: 0.4rem;
                  cursor: pointer;
                  font-size: 1.2rem;
                  font-weight: 400;
                  white-space: nowrap;
                }

                .select_header_placeholder {
                  font-size: 1.2rem;
                  line-height: 1.6rem;
                  font-weight: 400;
                  color: #606870;
                  white-space: nowrap;
                }
              }

              .select_background {
                position: fixed;
                width: 100vw;
                height: 100vh;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 15;
              }
              .select_options {
                z-index: 35;
                position: absolute;
                width: 80%;
                background: white;
                max-height: 22rem;
                border-radius: 0.8rem;
                overflow-y: auto;
                box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
                padding: 0 1.6rem;

                .select_option {
                  display: flex;
                  width: fit-content;
                  padding: 1.2rem 0;

                  > div {
                    padding: 0.3rem 0.8rem 0.3rem 0.8rem;
                    border-radius: 0.4rem;
                    cursor: pointer;
                    font-size: 1.2rem;
                    font-weight: 400;

                    &:hover {
                      background: #ece9e9;
                    }
                  }
                }
              }

              .time_options_container{
                z-index: 35;
                position: absolute;
                width: 65%;
                background: white;
                max-height: 20rem;
                overflow-y: auto;
                box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
                padding: 0 0.8rem;

                button {
                  background: none;
                  border: none;
                  outline: none;
                  width: 100%;
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  padding: 1.2rem 1.6rem;
                  color: #495057;
                  cursor: pointer;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 22px;
                }
              }
            }

            .project_details_planner_input_group {
              width: 100%;
              padding: 1.5rem;
              position: relative;

              label {
                width: 100%;
                font-weight: 400;
                font-size: 1rem;
                color: #606870;
              }

              select {
                width: 100%;
                border: 0.1rem solid #dee2e6;
                border-radius: 0.4rem;
                padding: 0.6rem 0.6rem;

                &:focus {
                  outline: none;
                }
              }

              .select_header {
                width: 100%;
                height: 3.2rem;
                border: 0.1rem solid #dee2e6;
                border-radius: 0.4rem;
                padding: 0.6rem 0.8rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                .down_outline {
                  transform: rotate(0turn);
                  transition: transform 0.3s ease;
                }

                .open_down_outlined {
                  transform: rotate(0.5turn);
                }

                svg {
                  width: 1.2rem;
                  height: 1.2rem;
                }

                .selected_header_option {
                  padding: 0.3rem 0.8rem 0.3rem 0.8rem;
                  border-radius: 0.4rem;
                  cursor: pointer;
                  font-size: 1.2rem;
                  font-weight: 400;
                  white-space: nowrap;
                }

                .select_header_placeholder {
                  font-size: 1.2rem;
                  line-height: 1.6rem;
                  font-weight: 400;
                  color: #606870;
                  white-space: nowrap;
                }
              }

              .select_background {
                position: fixed;
                width: 100vw;
                height: 100vh;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 15;
              }
              .select_options {
                z-index: 35;
                position: absolute;
                width: 80%;
                background: white;
                max-height: 13.8rem;
                border-radius: 0.8rem;
                overflow-y: auto;
                box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
                padding: 0 0.8rem;

                .select_option {
                  margin: 0.8rem 0;
                  padding: 0.3rem 0.8rem 0.3rem 0.8rem;
                  background: #f1f3f5;
                  border-radius: 0.4rem;
                  cursor: pointer;

                  &:hover {
                    background: #ece9e9;
                  }
                }
              }
            }

            .task_multiselect_group {
              width: 100%;
              padding: 1.5rem;
              position: relative;

              label {
                width: 100%;
                font-weight: 400;
                font-size: 1rem;
                color: #606870;
              }

              .task_multiselect_header {
                width: 100%;
                height: 3.2rem;
                border: 0.1rem solid #dee2e6;
                border-radius: 0.4rem;
                padding: 0.6rem 0.8rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                .task_multiselect_down_outline {
                  transform: rotate(0turn);
                  transition: transform 0.3s ease;
                }

                .task_multiselect_open_down_outlined {
                  transform: rotate(0.5turn);
                }

                svg {
                  width: 1.2rem;
                  height: 1.2rem;
                }

                .task_multiselect_header_placeholder {
                  font-size: 1.2rem;
                  line-height: 1.6rem;
                  font-weight: 400;
                  color: #606870;
                  white-space: nowrap;
                }
              }

              .task_multiselect_content {
                position: absolute;
                display: flex;
                width: 100%;

                .task_multiselect_background {
                  position: fixed;
                  width: 100vw;
                  height: 100vh;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  left: 0;
                  z-index: 15;
                }

                .task_multiselect_sidebar {
                  background: white;
                  padding: 0.6rem 0.8rem;
                  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
                  display: block;
                  overflow: visible!important;
                  width: 26%;

                  .multiselect_open_active {
                    color: #1F73E2;
                  }

                  .multiselect_down_outline {
                    transform: rotate(0turn);
                    transition: transform 0.3s ease;
                  }

                  .multiselect_open_down_outlined {
                    color: #1F73E2;
                    transform: rotate(-0.25turn);
                  }

                  svg {
                    width: 1.2rem;
                    height: 1.2rem;
                  }

                  button {
                    background: none;
                    border: none;
                    outline: none;
                    width: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    gap: 0.8rem;
                    padding: 0.8rem 0;
                    cursor: pointer;
                  }
                }
                .task_multiselect {
                  width: 70%;
                  background: white;
                  margin-left: 0.2rem;
                  margin-bottom: 1rem;

                  .multiselect_options {
                    z-index: 20;
                    position: absolute;
                    width: 50%;
                    background: white;
                    max-height: 30rem;
                    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);

                    .multiselect_options_header {
                      padding: 1.2rem 1.6rem;
                      font-size: 1.2rem;
                      font-weight: 600;
                      line-height: 22px;
                      border-bottom: 1px solid #F1F3F5;
                    }

                    .multiselect_options_search {
                      padding: 0 1.6rem;
                      display: flex;
                      justify-content: start;
                      align-items: center;
                      border-bottom: 1px solid #F1F3F5;

                      svg {
                        width: 16px;
                        height: 16px;
                      }
                      input {
                        background: none;
                        border: none;
                        padding: .7rem 0.8rem;
                        width: 80%;
                        outline: none;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 22px;
                      }
                    }

                    .multiselect_options_container {
                      overflow-y: auto;
                      height: 16rem;
                      border-bottom: 1px solid #F1F3F5;
                      width: 100%;

                      >div {
                        width: 100%;
                        height: 4rem;
                        display: flex;
                        justify-content: start;
                        align-items: center;

                        label {
                          padding: 1.2rem 1.6rem;
                          width: 100%;
                          cursor: pointer;

                          &:hover {
                            background: #f7f7f8;
                          }

                          span {
                            margin-left: 0.8rem;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22px;
                            color: #343A40;
                          }
                        }
                      }
                    }

                    .multiselect_options_footer{
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding: 1.2rem 1.6rem;

                      .multiselect_reset_btn {
                        color: #868E96;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        border: none;
                        background: none;
                        cursor: pointer;

                        &:hover {
                          color: #595a60;
                        }
                      }

                      .right_btns {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 1.6rem;

                        .multiselect_cancel_btn {
                          color: #3D92EC;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: 24px;
                          border: none;
                          background: none;
                          cursor: pointer;

                          &:hover {
                            color: #2a77cb;
                          }
                        }

                        .multiselect_save_btn {
                          color: #FFF;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: 24px;
                          padding: 0.4rem 1rem;
                          border: none;
                          border-radius: 0.8rem;
                          background: #3D92EC;
                          cursor: pointer;

                          &:hover {
                            background: #2a77cb;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .tasks_add_new_task {
          button {
            width: 100%;
            background: none;
            border: 0.1rem dashed #dee2e6;
            color: #606870;
            font-size: 1.2rem;
            line-height: 2.2rem;
            font-weight: 600;
            border-radius: 0.6rem;
            cursor: pointer;
            padding: 0.4rem 0;

            &:hover {
              background: #f3f5f6;
            }
          }
        }
      }
    }

    .product_information_wrap {
      border: 0.1rem #e9ecef solid;
      border-radius: 0.4rem;
      width: 90%;
      margin: 0 auto;
      overflow: hidden;

      .product_information_header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        background: #f8f9fa;
        border-bottom: 0.1rem #e9ecef solid;

        h4 {
          color: #495057;
          margin-bottom: 0 !important;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 600;
          line-height: 2.2rem;
        }
      }

      .product_information_content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 1.5rem;
        font-size: 1.2rem;

        .product_information_container {
          border: 0.1rem #e9ecef solid;
          border-radius: 1rem;
          width: 48.5%;
          margin: 1rem 0;
          padding: 0.5rem 1.5rem;

          .product_information_container_content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.8rem 0;
            gap: 0.8rem;

            .product_information_font_semibold {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .history_and_notes_wrap {
    flex: 29%;
    padding: 1.5rem;
    font-size: 1.2rem;

    .container {
      border: 0.1rem #e9ecef solid;
      border-radius: 0.7rem;
      overflow: hidden;

      .header_btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        width: 100%;
        padding: 1.5rem;
        background: #f8f9fa;
        border-bottom: 0.1rem #e9ecef solid;
        cursor: pointer;

        .text_container {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 1.2rem;

          span {
            margin-left: 0.4rem;
          }
        }

        .down_outline {
          transform: rotate(0turn);
          transition: transform 0.3s ease;
        }

        .open_down_outlined {
          transform: rotate(0.5turn);
        }
      }

      .content_container {
        max-height: 0;
        overflow-y: auto;
        transition: max-height 0.3s ease;
      }

      .content {
        padding: 1.5rem;

        .notes_input_group {
          width: 100%;

          label {
            width: 100%;

            input {
              width: 100%;
              border: 0.1rem solid #dee2e6;
              border-radius: 0.4rem;
              padding: 0.8rem;

              &:active {
                outline: none;
              }
            }
          }
        }

        .comment {
          border: 0.1rem solid #dee2e6;
          border-radius: 0.4rem;
          padding: 0.8rem;
          display: flex;
          justify-content: center;
          align-items: start;
          margin-top: 1.6rem;
          gap: 1.2rem;

          .comment_user_icon {
            width: 2.4rem;

            img {
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          .comment_user_content {
            .comment_user_header {
              display: block;
              > div {
                width: 100%;
              }

              .comment_user_fullname {
                font-size: 1.2rem;
                font-weight: 600;
                line-height: 2rem;
                color: #343a40;
              }

              .comment_user_date {
                color: #343a40;
                margin-top: 0.2rem;
                font-size: 1rem;
                line-height: 2rem;
                font-weight: 400;
              }
            }

            .comment_user_comment {
              margin-top: 1.2rem;

              p {
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.9rem;
                color: #343a40;
              }
            }
          }
        }
      }

      .open {
        max-height: 25rem;
      }
    }

    .notes_container {
      margin-top: 1.5rem;
    }
  }

  .client_information_wrapper {
    margin: 0 auto;
    width: 90%;
    border: 0.1rem #e9ecef solid;
    border-radius: 0.4rem;

    .client_information_header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;
      background: #f8f9fa;
      border-bottom: 0.1rem #e9ecef solid;

      h4 {
        color: #495057;
        margin-bottom: 0 !important;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.2rem;
      }
    }

    .product_details_header {
      border-top: 0.1rem #e9ecef solid;
    }

    .client_information_and_project_container {
      display: flex;

      .client_information_and_project_group {
        width: 50%;
        padding: 0 1.5rem;
        margin: 1.5rem 0;
        font-size: 1.2rem;

        .information_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #343a40;
          padding: 1rem 0;
          gap: 2rem;

          .status {
            display: flex;
            align-items: center;
            border-radius: 0.4rem;
            padding: 0.2rem 1rem;
            background: #e9ecef;
            font-size: 1.2rem;
            line-height: 1.6rem;
            font-weight: 400;
          }
        }
      }

      .group_border_right {
        border-right: 0.1rem #e9ecef solid;
      }
    }
  }

  .document_wrapper {
    margin: 0 auto;
    width: 90%;
    border: 0.1rem #e9ecef solid;
    border-radius: 0.4rem;

    .document_header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;
      background: #f8f9fa;
      border-bottom: 0.1rem #e9ecef solid;

      h4 {
        color: #495057;
        margin-bottom: 0 !important;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.2rem;
      }
    }

    .document_container {
      padding: 0 1.5rem 1.5rem 1.5rem;

      .document_group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 0.1rem #e9ecef solid;
        border-radius: 0.4rem;
        padding: 0.8rem 1.6rem 0.8rem 1.6rem;
        margin-top: 1.5rem;
        font-size: 1.2rem;

        .download_group {
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          color: #1f73e2;
          cursor: pointer;

          svg {
            width: 1.9rem;
            height: 1.9rem;
          }
        }
      }
    }
  }
}

.condense {
  margin-right: 0;
  height: 95%;
  width: 40%;
  top: 0;

  .project-view-modal-header{
    border-top-right-radius: 0!important;
  }

  .ant-modal-content {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .tasks_wrap {
      .task_container {
        .task_content {
          .project_details_input_group {
            width: 100% !important;
          }
        }
      }
    }

    //.project_details_wrap {
    //  .project_details_container {
    //    .project_details_content {
    //      .project_details_input_group {
    //        width: 100%;
    //      }
    //    }
    //  }
    //}

    .client_information_wrapper {
      .client_information_and_project_container {
        display: block;

        .client_information_and_project_group {
          width: 90% !important;
          margin: 1.5rem auto;
          padding-bottom: 1.5rem;
        }

        .group_border_right {
          border-right: 0;
          border-bottom: 0.1rem #e9ecef solid;
        }
      }
    }

    //.product_information_wrap {
    //  .product_information_content {
    //    display: block;
    //
    //    .product_information_container {
    //      width: 100%;
    //    }
    //  }
    //}

    .details_and_activity_switcher {
      padding: 1.5rem 2rem 1rem 2rem;
      border-bottom: solid 0.1rem #dee2e6;

      display: flex;
      justify-content: center;

      .btns_container {
        box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
        border-radius: 0.8rem;

        button {
          background: none;
          border: none;
          padding: 0.4rem 0;
          font-size: 1.4rem;
          font-weight: 600;
          color: #adb5bd;
          cursor: pointer;
        }

        .details_btn {
          width: 9.6rem;
          border-right: 0.05rem #e9ecef solid;
        }

        .activity_btn {
          width: 9.6rem;
          border-left: 0.05rem #e9ecef solid;
        }

        .active {
          color: #1f73e2;
        }
      }
    }

    .project-view-modal-container {
      display: block;

      .project-view-modal-sidebar {
        display: inline-flex;
        justify-content: space-between;
        border-right: 0;
        box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
        width: 100%;
        gap: 0.8rem;
        overflow-x: auto;

        button {
          background: none;
          border-left: none;
          white-space: nowrap;
          padding: 1rem;
          display: flex;
          justify-content: center;
          margin: 0 0.5rem;

          &:hover {
            background: none;
            border-left: none;
            border-bottom: 0.3rem solid #3d92ec;
            color: #212529!important;
          }
        }

        .active {
          color: #212529!important;
          border-bottom: 0.3rem solid #3d92ec;
        }
      }
    }

    .project-view-modal-content {
      margin-top: 3rem;
      height: calc(100vh - 40rem);
    }

    .history_and_notes_wrap {
      margin-top: 3rem;
    }
  }
}

@media screen and (min-width: @screen-lg) {
  .condense {
    margin-right: 0;
    height: 95%;
    width: 36.5% !important;
    top: 0;

    .project-view-modal-content {
      height: calc(100vh - 27rem)!important;
    }
  }
}

@media screen and (max-width: 1214px) {
  .custom-modal {

    .project-view-modal-header {
      background: #F1F3F5;
      border-top-right-radius: 2rem;
      border-top-left-radius: 2rem;

      .condense-btn {
        border: 1px solid #DEE2E6!important;
        background: #F8F9FA!important;
      }
    }

    .ant-modal-content {
      .details_and_activity_switcher {
        padding: 1.5rem 2rem 1rem 2rem;
        border-bottom: solid 0.1rem #dee2e6;

        display: flex;
        justify-content: center;

        .btns_container {
          box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
          border-radius: 0.8rem;

          button {
            background: none;
            border: none;
            padding: 0.4rem 0;
            font-size: 1.4rem;
            font-weight: 600;
            color: #adb5bd;
            cursor: pointer;
          }

          .details_btn {
            width: 9.6rem;
            border-right: 0.05rem #e9ecef solid;
          }

          .activity_btn {
            width: 9.6rem;
            border-left: 0.05rem #e9ecef solid;
          }

          .active {
            color: #1f73e2;
          }
        }
      }

      .details_and_activity_switcher {
        padding: 1.5rem 2rem 1rem 2rem;
        border-bottom: solid 0.1rem #dee2e6;

        display: flex;
        justify-content: center;

        .btns_container {
          box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
          border-radius: 0.8rem;

          button {
            background: none;
            border: none;
            padding: 0.4rem 0;
            font-size: 1.4rem;
            font-weight: 600;
            color: #adb5bd;
            cursor: pointer;
          }

          .details_btn {
            width: 9.6rem;
            border-right: 0.05rem #e9ecef solid;
          }

          .activity_btn {
            width: 9.6rem;
            border-left: 0.05rem #e9ecef solid;
          }

          .active {
            color: #1f73e2;
          }
        }
      }

      .project-view-modal-container {
        display: block;

        .project-view-modal-sidebar {
          display: inline-flex;
          justify-content: space-between;
          border-right: 0;
          box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
          width: 100%;
          gap: 0.8rem;
          overflow-x: auto;

          button {
            background: none;
            border-left: none;
            white-space: nowrap;
            padding: 1rem;
            display: flex;
            justify-content: center;
            margin: 0 0.5rem;

            &:hover {
              background: none;
              border-left: none;
              border-bottom: 0.3rem solid #3d92ec;
              color: #212529;
            }
          }

          .active {
            color: #212529;
            border-bottom: 0.3rem solid #3d92ec;
          }
        }
      }

      .project-view-modal-content {
        margin-top: 3rem;
        height: calc(100vh - 40rem);
        border: none;
      }

      .history_and_notes_wrap {
        margin-top: 3rem;
      }
    }
  }

  .condense {
    margin-right: 0;
    height: 95%;
    width: 48.5% !important;
    top: 0;

    .project-view-modal-content {
      height: calc(100vh - 27rem)!important;
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .ant-modal {
    max-width: 100vw !important;
    height: calc(100vh - 6.8rem)!important;
  }

  .ant-modal-content {
    box-shadow: none;
  }

  .custom-modal {
    width: 100% !important;
    margin: 0 !important;
    top: 0 !important;
    right: 0;
    left: 0;
    height: 92vh;
    position: relative;

    .task_multiselect_content {
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .task_multiselect_sidebar {
        width: 80%;
      }
      .task_multiselect {
        margin-top: 0.2rem;
        margin-left: 0!important;
      }
    }

    .project-view-modal-content {
      height: calc(100vh - 30rem)!important;
    }

    .project-view-modal-header {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      .header-button-container {
        .condense-btn {
          display: none;
        }
      }
    }

    .ant-modal-content {
      border-radius: 0;
      height: 100%;

      .project_details_wrap {
        .project_details_container {
          .project_details_content {
            .project_details_input_group {
              width: 100%;
            }
          }
        }
      }

      .tasks_wrap {
        .task_container {
          .task_content {
            .project_details_input_group {
              width: 100% !important;
            }
          }
        }
      }

      .product_information_wrap {
        .product_information_content {
          display: block;

          .product_information_container {
            width: 100%;
          }
        }
      }

      .client_information_wrapper {
        .client_information_and_project_container {
          display: block;

          .client_information_and_project_group {
            width: 90% !important;
            margin: 1.5rem auto;
            padding-bottom: 1.5rem;
          }

          .group_border_right {
            border-right: 0;
            border-bottom: 0.1rem #e9ecef solid;
          }
        }
      }

      .document_wrapper {
        .document_container {
          .document_group {
            span {
              max-width: 55%;
              white-space: initial;
            }

            .download_group {
              width: 45%;
              white-space: nowrap !important;
              display: flex;
              justify-content: end;
            }
          }
        }
      }
    }

    .ant-modal-body {
      border-radius: 0 !important;
    }
  }
}