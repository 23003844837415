@client-padding-right: 1.7rem;

.client-information {
  width: 30%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  border-radius: 8px;
  position: sticky;
  left: @client-padding-right;
  margin-bottom: 1.7rem;
  top: 10.5rem;

  .information__section {
    background-color: white;
    padding-bottom: 10rem;
  }

  .tab__switcher {
    display: grid;
    grid-template-columns: 1fr 1fr;

    p {
      text-align: center;
      font-weight: 600;
      font-size: 1.4rem;
      color: #343a40;
      margin-bottom: 0;
    }

    a {
      padding: 0.95rem 0;
      background-color: #e9ecef;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .project__tab__container {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    background-color: white;
    border-radius: 0 0 8px 8px;

    .client-information__header {
      justify-content: flex-end;
      margin-right: 0;
      padding-top: 2.1rem;
    }

    .type__field {
      display: flex;
      justify-content: space-between;
      border: 1px solid #63aff4;
      border-radius: 4px;
      padding: 0.8rem 0.9rem;
      margin-bottom: 4.3rem;

      &__text {
        font-weight: 600;
        font-size: 1.4rem;
        color: #343a40;
      }

      &__icon {
        span {
          color: #343a40;
          font-size: 1.3rem;
        }
        display: flex;
        align-items: center;
        gap: 0.8rem;
      }
    }
  }

  .tab__switcher__active {
    background-color: white !important;
  }

  &__header {
    display: flex;
    margin-right: @client-padding-right;
    justify-content: space-between;
    font-size: 1.4rem;
    line-height: 3.2rem;
    padding-bottom: 2.4rem;
    align-items: center;
    img {
      height: fit-content;
      cursor: pointer;
    }
  }

  .client-info-fields {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;

    &__field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2.4rem;
      gap: 1.6rem;

      .field-name {
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 2.4rem;
        color: #343a40;
      }

      .email-field {
        width: 5.5rem;
      }

      .field-value {
        display: inline-flex;
        align-items: center;
        font-weight: 400;
        font-size: 1.4rem;
        font-weight: 400;
        overflow-x: auto;
        overflow: hidden;
        word-break: break-all;
        color: #868e96;

        &__status {
          width: 50%;

          .ant-select-selector {
            background-color: transparent;
            padding-right: 2.3rem;
            padding-left: 0.9rem;
            border: 1px solid #dee2e6;

            .ant-select-selection-item {
              display: flex;
              align-items: center;
              justify-content: start;
            }
          }

          .ant-select-suffix {
            color: #868e96;
          }

          &__select {
            .option {
              display: flex;
              align-items: center;

              &__text {
                background-color: #e9ecef;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 2rem;
                border-radius: 0.4rem;
                padding: 0 1rem;

                span {
                  display: inline-block;
                  max-width: 12rem;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                }
              }

              &__label_icon {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          .option {
            display: flex;
            align-items: center;
            gap: 0.9rem;
          }

          .ant-select {
            width: 100%;
          }
        }

        img {
          margin-left: 0.8rem;
          width: 1.6rem;
          height: 1.4rem;
        }
      }
    }

    .project-details-header {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 2.4rem;
      margin-top: 2.1rem;

      img {
        height: fit-content;
        cursor: pointer;
      }
    }
  }
}

.offer__status__select__dropdown {
  .option {
    &__label_icon {
      display: none;
    }

    &__text {
      background-color: transparent !important;
      color: #343a40 !important;
    }
  }
}

@media (max-width: 1440px) {
  .client-information {
    width: 45%;
  }
}
@media (max-width: @screen-lg) {
  .client-information {
    display: none;
  }
}
