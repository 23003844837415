.inspectionMainScreen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  .inspectionMainContent {
    width: 90%;
    display: flex;
    flex-direction: column;
    position: relative;
    .inspectionButton {
      display: flex;
      color: @main-blue;
      cursor: pointer;
      align-items: center;
      ::first-letter {
        text-transform: capitalize;
      }
      .allInViewIcon {
        height: 2.2rem;
        margin-right: 1rem;
        border-radius: 30%;
      }
    }
    .inspectionTableText {
      display: flex;
      cursor: pointer;
      align-items: center;
    }
    .tableControlButtons {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: 1.5rem;
    }
    .allInViewLogoAndText {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      border-radius: 8px;
    }
  }
}

.inspection__container {
  display: flex;
  align-items: center;
  gap: 1rem;

  .inspection__pdf__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 3.2rem;
    border-radius: 8px;
    background: rgba(239, 249, 255, 1);
  }
  
  .inspection__pdf__icon:hover {
    background: rgba(208, 234, 254, 1);
  }
}

.secondWizard__checkboxs {
  display: flex;
  flex-direction: column;
}

.inspection_wizard_form {
  overflow-y: auto;

  &__instruction {
    font-style: italic;
    font-weight: normal;
    font-size: 1.22rem;
    line-height: 2.4rem;
    color: #868e96;
    display: block;
    margin-top: 2rem;
  }
}

.upload_image {
  margin-bottom: 0;
}

.wizards_title {
  text-align: center;
  padding-bottom: 1rem;
  text-transform: uppercase;
}

.input_number {
  width: 100%;
  border-radius: 0.8rem;
}

.wizards_checkbox_space {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
}

.allInViewIframe {
  width: 100%;
  min-height: 50rem;
  height: 100%;
  border: none;
  overflow: hidden;
}

.all_in_view_loading {
  display: flex;
  flex-direction: column;
  &__text {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
}

.all_in_view_retry {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__button {
    margin-top: 1rem;
    padding: 0.5rem 3rem;
    cursor: pointer;
    :hover {
      cursor: pointer;
    }
  }
}

@media (max-width: @screen-lg) {
  .inspectionMainScreen {
    height: 100%;
    flex-grow: 1;

    .inspectionMainContent {
      height: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
