.select {
  width: 100%;
  position: relative;
  .inputWrapper {
    display: flex;
    button {
      margin-left: 1rem;
    }
  }
  &__input {
    width: 100%;
  }

  .dropdownWrapper {
    position: absolute;
  }
  .dropdown {
    width: 100%;
    max-height: 20rem;
    overflow: scroll;
    z-index: 10;
    flex-direction: column;
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    border-radius: 1.2rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0.2rem;
    border: solid 1px #e2e6e9;

    &__item {
      width: 100%;
      padding: 1rem;
      cursor: pointer;
    }
    &__item:hover {
      background-color: #f2f4f5;
      width: 100%;
      padding: 1rem;
    }
  }
}
