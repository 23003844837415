@import '~antd/dist/antd.less';

/* animations */
@import './animations/animations.less';

/* variables */
@import './variables/colors.less';
@import './variables/sizes.less';

/* customized components */
@import './custom/singatureDoublebox.less';
@import './custom/agreeDeleteModal.less';
@import './custom/selectWithInput.less';
@import './custom/signatureModal.less';
@import './custom/importCsvModal.less';
@import './custom/showFieldError.less';
@import './custom/custom-upload.less';
@import './custom/errorModal.less';
@import './custom/pagination.less';
@import './custom/accordion.less';
@import './custom/dropdown.less';
@import './custom/controle.less';
@import './custom/buttons.less';
@import './custom/inputs.less';
@import './custom/table.less';
@import './custom/modal.less';

/* custom components */
@import './custom/selectlanguage.less';
@import './custom/navigation.less';
@import './custom/search.less';

/* layout styles */
@import './layout/mainLayout.less';
@import './layout/homeLayout.less';
@import './layout/header.less';

/* screens styles */
@import './screens/offersClientInformation.less';
@import './screens/inspectionMainScreen.less';
@import './screens/manage/manageScreen.less';
@import './screens/financialReporting.less';
@import './screens/materialReporting.less';
@import './screens/projectsOverview.less';
@import './screens/generalReporting.less';
@import './screens/projectCreation.less';
@import './screens/project/project.less';
@import './screens/reportingSceen.less';
@import './screens/confirmOrder.less';
@import './screens/planningTool.less';
@import './screens/loginScreen.less';
@import './screens/leadIframe.less';
@import './screens/homeScreen.less';
@import './screens/quotation.less';
@import './screens/dashboard.less';
@import './screens/comments.less';
@import './screens/delivery.less';
@import './screens/company.less';
@import './screens/profile.less';

/* Planning */
@import './screens/planning/planningCalendar.less';
@import './screens/planning/planning.less';

@import './screens/offers.less';
@import './screens/leads.less';

/* general pages */
@import './generalPages/pageNotFound.less';

@import './suspenseFallback.less';
@import './quotationClone.less';

/* editor */
@import './editor/editor.less';

/* project view modal */
@import "./screens/projectViewModal.less";

body {
  background: #f8f9fa;
  overflow-x: hidden;
}

#root {
  min-height: 100%;
  width: 100%;
  font-family: open-sans, sans-serif, Arial, Helvetica;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  ::i-block-chrome,
  #root {
    font-family: -apple-system, sans-serif, Arial, Helvetica;
  }
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  #root {
    font-family: -apple-system, sans-serif, Arial, Helvetica;
  }
}

html {
  font-size: 10px !important;
}

@primary-color: #3D92EC;@error-color: #FF0000;