@active-border: 0.2rem solid #3d92ec;
@active-font-weight: 600;
@grey-border: 1px solid #e9ecef;

.planning-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  padding-left: 3.8rem;
  padding-right: 3.8rem;

  .planning-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 8rem;
    border-bottom: @grey-border;

    .text-and-startIcon {
      display: flex;
      align-items: center;
      gap: 2rem;
      h2 {
        font-size: 1.6rem;
        line-height: 3.2rem;
        margin: 0;
        font-weight: 700;
      }
    }
  }

  .list-header-wrapper {
    display: flex;
    height: 4.6rem;
    border-bottom: @grey-border;
    font-size: 1.2rem;
    line-height: 2.4rem;

    .project-list {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      margin-right: 3.2rem;
      height: auto;

      cursor: pointer;

      &__active {
        font-weight: @active-font-weight;
        border-bottom: @active-border;
      }
    }

    .task-planning-and-calendar-switcher {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      gap: 0.8rem;
      height: auto;

      &__active {
        border-bottom: @active-border;
      }

      .separator {
        width: 1px;
        height: 1.1rem;
        background: #adb5bd;
      }

      .task-planning {
        display: flex;
        align-items: center;
        cursor: pointer;
        &__active {
          font-weight: @active-font-weight;
        }
      }

      .calendar-switcher {
        display: flex;
        align-items: center;
        cursor: pointer;
        &__active {
          font-weight: @active-font-weight;
        }
      }
    }

    .task-radio-switcher-wrapper {
      display: flex;
      margin-left: 2.4rem;
      margin-bottom: 0.8rem;

      flex-direction: column-reverse;

      .task-radio-switcher {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        font-size: 1rem;
        height: fit-content;
        padding: 0.4rem 0.6rem;
        background: #fff;
        border: 1px solid #e9ecef;
        border-radius: 0.4rem;
        .ant-radio,
        .ant-radio-wrapper {
          font-size: 1rem;
        }

        .ant-radio-inner {
          width: 1.2rem;
          height: 1.2rem;
        }

        .ant-radio-wrapper {
          flex-direction: row-reverse;
        }
        .ant-radio-group {
          label:nth-child(2) {
            border-left: 1px solid #e9ecef;
          }
        }
      }
    }
  }

  .planning-search {
    margin-top: 1.8rem;
    width: 17.4rem;
    position: relative;
    margin-bottom: 2.4rem;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0.8rem;
    }
    .ant-input {
      padding-left: 2.4rem;
      font-size: 1rem;
      height: 3.2rem;
    }
  }

  .planning-project-table-wrapper {
    .status {
      width: fit-content;
      background: #f8f9fa;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      font-size: 1.2rem;
      line-height: 2.2rem;
      border-radius: 0.4rem;
      color: #606870;
      display: flex;
      align-items: center;
    }

    .not-paid {
      color: #fa541c;
    }
  }

  .planning-tables {
    .ant-table-content,
    .ant-table {
      border-radius: 1.2rem;
    }

    .ant-table.ant-table-bordered > .ant-table-container {
      border-left: none;
    }

    .ant-table {
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.06));
    }

    table {
      border-right: 1px solid #dee2e6;
      thead {
        background: #f1f3f5;

        th,
        tr {
          font-weight: 600;
          border-left: 1px solid #dee2e6;
          border-right: 1px solid #dee2e6;
        }
      }

      tbody {
        tr {
          td {
            border-left: 0.1px solid #dee2e6;
            border-right: 0.1px solid #dee2e6;
          }
        }
      }
    }
  }
}

@media (max-width: @screen-lg) {
  .planning {
    padding-bottom: 7rem;
  }
}

.planning {
  .list-header-wrapper {
    margin-top: 0.7rem;
  }

  .header {
    padding-left: 3.8rem;

    .back-link {
      display: none;
    }

    .planning__title--desktop {
      margin-bottom: 0;
      color: #495057;
      font-size: 1.6rem;
      font-weight: 700;
    }
  }
}

@media (max-width: @screen-sm) {
  .planning-wrapper {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .planning {
    .header {
      padding-left: 0;

      .back-link {
        display: block;
      }

      .planning__title--desktop {
        display: none;
      }
    }
  }
}
