.reporting {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 2.4rem;
    margin-top: 9rem;
    color: #343a40;
  }

  &__content {
    width: 90%;
    max-width: 118rem;
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr;
    margin-top: 5rem;
  }
  &__card {
    width: 100%;
    height: 10rem;
    flex-grow: 1;
    // filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.06));
    box-shadow: 0px 6px 6px 0 rgba(0, 0, 0, 0.06);
    background-color: white;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__cardtext {
    font-size: 20px;
    color: #343a40;
  }
}
@media (max-width: @screen-lg) {
  .reporting {
    &__content {
      gap: 6.3rem;
      grid-template-columns: 1fr;
    }
    &__card {
      width: 100%;
    }
  }
}
@media (max-width: @screen-lg) {
  .reporting {
    padding-bottom: 160px;
  }
}
@media (max-width: @screen-sm) {
  .reporting {
    padding-bottom: calc(@footer-height-mobile + 2rem);
    &__card {
      width: 100%;
      height: 5.8rem;
    }

    &__cardtext {
      font-size: 16px;
      line-height: 24px;
    }

    &__content {
      gap: 2rem;
    }
  }
}
