/* SignatureQuotation */
.signatures {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;

  &__box {
    width: 32.8rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 1.3rem 2rem;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 1.4rem;
    color: #212529;
    text-transform: capitalize;
  }

  &__buttonBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11rem;

    &__image {
      border: 0.8px solid #63aff4;
      border-radius: 9px;
      width: 100%;
      background: #fff;
    }

    .signatures__buttons__container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
  .ant-picker,
  .ant-picker-disabled {
    background: white;
  }
}

@media (max-width: 1270px) {
  .signatures {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    &__box {
      width: 32.8rem;
    }
  }
}
