.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 13px;
  font-size: 14px;
}
.ant-dropdown-menu-title-content > a {
  font-size: 14px;
}
