/* import wizards */
@import './wizards/inspectionWizard.less';
@import './wizards/surveyWizard.less';
@import './wizards/checkWizard.less';
@import './wizards/contactWizard.less';
@import './wizards/clientWizard.less';
@import './wizards/optionsWizard.less';

.project {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f8f9fa;

  height: 100%;
  min-height: 100vh;

  ::-webkit-scrollbar {
    width: 0.2rem;
  }

  ::-webkit-scrollbar-track {
    background: #dee2e6;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background: #adb5bd;
    border-radius: 15px 15px 0px 0px;
  }

  &__footer {
    width: 80rem;
    margin-top: 3.2rem;
    display: flex;
    justify-content: flex-end;
  }

  .moveWizards {
    right: 0;
    position: absolute;
    float: right;
    bottom: -6rem;
    width: 100%;
    display: flex;
    padding-top: 2.4rem;
    justify-content: end !important;
    gap: 1.6rem;
    .previousButton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.8rem;
      ::first-letter {
        text-transform: capitalize;
      }
      svg {
        margin: 0;
        padding: 0;
        margin-right: 1rem;
      }
    }
    .nextButton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.8rem;
      ::first-letter {
        text-transform: capitalize;
      }

      svg {
        padding: 0;
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
  &__steps {
    margin-top: 3.5rem;
    width: 100%;
    margin-bottom: 3.5rem;

    .ant-steps-item-container {
      cursor: pointer;
    }
  }

  .project_guard {
    margin-top: 3.5rem;
    font-size: 2rem;
  }

  .offers__products__container {
    .ant-collapse {
      background-color: transparent;
    }

    .page__container {
      display: flex;
      justify-content: center;
    }
  }

  .tab__switcher__container {
    margin-top: 2rem;
    padding-top: 1.5rem;
    width: 100%;
    display: flex;
    margin-bottom: 2.2rem;
    justify-content: center;

    &__link {
      height: 5.8rem;
      width: 18.7rem;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        font-weight: 400;
        font-size: 2rem;
        color: #868e96;
      }
    }

    .active {
      background-color: white;
      font-weight: 600;
      box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.1);
      border-radius: 10px 10px 0px 0px;
      border-bottom: 2px solid #3d92ec;
      padding-top: 0.2rem;

      a {
        color: #343a40 !important;
      }
    }
  }
}

.capitalize_text {
  text-transform: capitalize;
}

.project__projectNotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  padding: 4rem 2rem;
}

.group-label-error {
  div {
    color: @warning-red !important;
  }
}

@media (max-width: @screen-lg) {
  .project {
    width: 100%;
    margin-bottom: 0;
    min-height: 100vh;
    justify-content: initial;

    .moveWizards {
      position: initial;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      flex-grow: 1;
    }
    &__steps {
      width: 90%;
    }
  }
}

@media (max-width: @screen-sm) {
  .project {
    &__steps {
      .ant-steps-item-icon {
        width: 2.4rem;
        height: 2.4rem;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
