.material {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 160px;

  &__title {
    margin-top: 9rem;
    font-size: 2.4rem;
    color: #212529;
  }

  &__content {
    width: 90%;
  }
  .contentheader {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 4rem;
    margin-bottom: 1.6rem;
    gap: 1.6rem;
  }
  .ant-table-row {
    cursor: default;
  }
}

@media (max-width: @screen-lg) {
  .material {
    padding-bottom: 160px;
    .contentheader {
      gap: 2.3rem;
      &__search {
        width: 100%;
      }
    }
  }
}

@media (max-width: @screen-sm) {
  .material {
    .contentheader {
      flex-direction: column;
      gap: 2rem;
      margin-top: 4rem;
    }
  }
}
