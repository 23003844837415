.ImportCvsModal {
  &__content {
    min-height: 32.6rem;
    height: 32.6rem;
    width: 100%;
    padding-left: 6.6rem;
    padding-right: 6.6rem;
    margin-top: 4.6rem;
    margin-bottom: 8.5rem;
    .ant-upload {
      border-radius: 12px;
    }
  }

  &__dragger {
    margin-bottom: 3rem;
    padding: 5rem;
  }
  &__image {
    margin-bottom: 3rem;
  }
  &__button {
    margin-top: 3rem;
  }
  &__text {
    font-size: 1.4rem;
    color: #868e96;
  }
}

@media (max-width: @screen-lg) {
  .ImportCvsModal {
    &__content {
      padding-left: 8.8rem;
      padding-right: 8.8rem;
      margin-top: 4.6rem;
    }
  }
}

@media (max-width: @screen-lg) {
  .ImportCvsModal {
    &__content {
      width: 90%;
      padding: 0;

      .ant-upload {
        border-radius: 12px;
      }
    }
  }
}
