.company {
  padding: 4.6rem 6.7rem 4.6rem 9rem;
  display: grid;
  grid-template-columns: 20rem 1fr;
  column-gap: 3.2rem;
  min-height: 90vh;

  &__affix {
    max-height: 16rem;
    z-index: 2;
  }

  &__sections-dropdown {
    display: none;
  }

  .ant-select-arrow {
    svg {
      width: 1.6rem;
      height: 1.6rem;
      fill: #606870;
    }
  }

  &__tab-selector {
    &__option {
      color: #868e96;
      font-size: 1.6rem;
      font-weight: 500;
      cursor: pointer;
      margin-top: 2rem;
      transition: all 0.2s ease-in-out;

      &--active {
        color: #1f73e2;
        font-weight: 600;
      }

      &--error {
        color: @warning-red;
      }
    }
  }

  &__tab-container {
    background-color: white;
    border-radius: 12px;
    display: grid;
    row-gap: 6rem;
    position: relative;

    &__form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__submit-button {
      font-weight: 600;
      font-size: 1.6rem;
      height: 4rem;
      align-self: end;
    }

    .setup-form {
      padding: 3.4rem 10rem 4.5rem 10rem;
    }

    .email-information-form,
    .financial-information-form {
      padding: 3.5rem 25%;
    }

    .lead-integration-form {
      padding: 5.9rem 25%;
    }

    .configuration-form {
      padding: 5.6rem 25%;
    }

    &__setup {
      &__logo-input {
        .ant-upload-list {
          &-picture-card-container,
          &-item,
          &-item-image {
            width: 14rem;
            height: 14rem;
            border-radius: 100%;
            margin: 0;
            padding: 0;
          }

          &-picture-card-container {
            margin-right: 4.5rem;
          }

          &-item-info {
            border-radius: 100%;
          }

          &-item-thumbnail img {
            object-fit: contain;
          }

          &-item-card-actions-btn {
            display: none;
          }
        }
        .ant-upload {
          height: 14rem;
          background: transparent;
          border: none;
          width: fit-content;

          .ant-btn-primary {
            height: 4rem;
            font-size: 1.6rem;
            font-weight: 600;
            padding: 0.8rem 1.6rem;
          }
        }

        &__action_buttons {
          display: flex;
          gap: 1.4rem;
          flex-direction: column;

          &__delete-button {
            padding: 0.8rem 1.6rem;
            padding-left: 0;
            color: #ff4d4f;
            font-size: 1.6rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: fit-content;
            border: none;
            background: transparent !important;
            box-shadow: none;

            &:hover {
              color: #ff7875;
            }
          }
        }
      }

      &__input-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 4.8rem;

        .name {
          grid-column: span 2;
        }

        .pdf-upload {
          grid-column: span 2;
          display: grid;
          grid-template-columns: 15rem 1fr;
          column-gap: 4rem;

          label {
            font-size: 1.6rem;
          }

          .ant-btn-default {
            background-color: #3d92ec;
            border-radius: 4px;
            color: white;
            font-weight: 600;
            border: none;
            font-size: 1.3rem;
            height: 2.8rem;
          }

          .ant-btn[disabled] {
            background-color: #e9ecef;
            color: #868e96;
          }
        }

        .phone-number {
          input {
            color: rgba(0, 0, 0, 0.614);
          }
        }

        .privacy-statement {
          border-top: 1px solid #dee2e6;
          padding-top: 4.4rem;
          margin-top: 2rem;
          margin-bottom: 3.4rem;
        }
      }
    }

    &__configuration {
      &__switch-buttons {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 3.4rem;
      }

      label {
        font-size: 1.6rem !important;
      }

      &__tenant-login {
        margin-top: 4.8rem;
        padding-top: 4.8rem;
        border-top: 1px solid #dee2e6;

        button {
          height: 4rem;
          color: white;
          font-weight: 600;
          font-size: 1.6rem;
        }
      }
    }

    &__lead-integration {
      &__iframe-list {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        gap: 1.5rem;

        li {
          list-style-type: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .iframe-links-container {
          margin-bottom: 4.5rem;

          &__title {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            p {
              margin-bottom: 0;
              font-size: 1.6rem;
              color: #1f73e2;
            }
          }

          &__info-icon {
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
      }

      &__info-text {
        color: #606870;
        font-size: 1.4rem;
        line-height: 21px;
        font-weight: 400;
        margin-bottom: 2.2rem;
      }

      &__add-source {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 3.1rem;

        &__title {
          color: #495057;
          font-size: 1.6rem;
          font-weight: 400;
          margin-bottom: 0;
        }

        &__plus-icon {
          cursor: pointer;
          background: #1f73e2;
          border-radius: 12px;
          height: 3.2rem;
          width: 3.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &__source-list {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        gap: 4.5rem;

        &__item {
          padding-bottom: 4.5rem;
          display: flex;
          flex-direction: column;
          gap: 2.6rem;

          .source-copy,
          .source-delete {
            display: none;
          }

          .ant-input {
            background-color: transparent;
            color: #868e96;
            font-size: 1.6rem;
            font-weight: 400;
            cursor: default;
          }

          .iframe-embedded-code {
            input {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          .copy-icon,
          .delete-icon {
            cursor: pointer;
            width: 2.4rem;
            height: 2.4rem;
            position: absolute;
            right: -4.3rem;
            top: 5.2rem;
          }
        }
      }
    }
  }

  .ant-form-item {
    flex-direction: column;
    gap: 0.8rem !important;

    &-label {
      text-align: left;
    }

    label {
      color: #212529;
      font-size: 1.3rem;
      font-weight: 400;
    }

    label::after {
      display: none;
    }

    input,
    .react-tel-input,
    .react-tel-input .form-control {
      min-height: 4.8rem !important;
      font-size: 1.6rem;
      font-weight: 300 !important;
    }

    .ant-select-selector {
      min-height: 4.8rem;
      align-items: center;
    }

    .ant-select-selection-item {
      font-size: 1.6rem;
      color: #000000d9;
      font-weight: 300;
    }
  }

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 1300px) {
  .company {
    padding-left: 4rem;
    padding-right: 4rem;

    &__tab-container {
      .setup-form {
        padding-left: 5rem;
        padding-right: 5rem;
      }

      .email-information-form,
      .financial-information-form {
        padding: 3.5rem 15%;
      }

      .lead-integration-form {
        padding: 5.9rem 15%;
      }

      .configuration-form {
        padding: 5.6rem 15%;
      }
    }
  }
}

@media (max-width: @screen-lg) {
  .company {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 12.6rem;
    column-gap: 0;

    &__tab-container {
      .setup-form {
        padding-left: 3rem;
        padding-right: 3rem;
      }

      .email-information-form,
      .financial-information-form,
      .lead-integration-form,
      .configuration-form {
        padding-left: 10%;
        padding-right: 10%;
      }

      .lead-integration-form {
        min-height: 72vh;
      }

      .lead-integration-form {
        padding-right: 15%;
      }
    }
  }
}

@media (max-width: 700px) {
  .company {
    grid-template-columns: 1fr;
    padding: 0;
    padding-top: 1.3rem;

    &__affix {
      display: none;
    }

    &__sections-dropdown {
      display: block;
      padding: 0 1.8rem;

      .ant-select-single {
        width: 100% !important;
        margin-bottom: 4.1rem;

        .ant-select-selector {
          background: transparent;
          height: 4.8rem;
          border: 1.5px solid #63aff4;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
          color: #343a40;
          font-size: 1.6rem;
          font-weight: 500;
        }
      }

      &__title {
        color: #212529;
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        margin-bottom: 2.6rem;
      }
    }

    form {
      padding-bottom: 12.6rem !important;

      .ant-form-item {
        flex-wrap: wrap;
        flex-direction: column !important;
      }

      .privacy-statement {
        margin-bottom: 2.6rem;
      }

      .terms-of-conditions {
        margin-bottom: 8rem;
      }

      .pdf-upload {
        grid-template-columns: 12rem 1fr;

        .ant-upload-list-item-name {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 12.5rem;
        }

        .ant-upload-list-item-card-actions-btn {
          opacity: 1;
        }

        .ant-form-item-control {
          align-items: flex-end;
        }

        .ant-form-item-control-input-content {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }

        .ant-form-item-control-input-content > span {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }

    &__tab-container {
      &__setup {
        &__logo-input {
          padding-bottom: 2.8rem;
          border-bottom: 1px solid #e9ecef;

          .ant-btn-primary {
            height: 3.4rem !important;
            font-size: 1.4rem !important;
            padding: 0 1.6rem !important;
            font-weight: 500;
          }

          .ant-upload-list-item,
          .ant-upload-list-picture-card-container {
            width: 10.4rem;
            height: 10.4rem;
          }

          .ant-upload-list-picture-card-container {
            margin-right: 2.9rem;
          }

          .ant-form-item-control-input,
          .ant-upload-list {
            height: 10.4rem;
          }

          .ant-upload {
            height: 100%;
          }

          &__action_buttons {
            gap: 1.1rem;

            &__delete-button {
              padding: 0.8rem 1.6rem;
              font-size: 1.4rem;
              padding-left: 0;
            }
          }
        }
      }

      &__lead-integration {
        &__source-list {
          &__item {
            .copy-icon,
            .delete-icon {
              display: none;
            }

            .source-copy,
            .source-delete {
              display: block;
              margin-top: 1.8rem;
              font-size: 1.6rem;
              font-weight: 600;
            }

            .source-copy {
              color: #3d92ec;
            }

            .source-delete {
              color: #ff4d4f;
            }
          }
        }
      }

      &__configuration {
        &__switch-buttons {
          row-gap: 2.4rem;
        }

        &__tenant-login {
          margin-top: 6.5rem;

          .ant-btn-primary {
            padding: 0 1.6rem;
          }
        }
      }

      .email-information-form,
      .financial-information-form,
      .lead-integration-form,
      .configuration-form,
      .setup-form {
        padding-left: 1.8rem;
        padding-right: 1.8rem;
      }

      .setup-form {
        padding-top: 1.3rem;
      }

      .financial-information-form {
        padding-top: 2.6rem;
      }

      .lead-integration-form {
        padding-top: 4.2rem;
      }

      &__setup__input-group {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.8rem;

        .ant-form-item {
          grid-column: span 2;
        }

        .house_number {
          grid-column: 1 / 2;
        }

        .house_number_suffix {
          grid-column: 2 / 3;
          grid-row: 6 / 7;
        }
      }

      .configuration-form {
        display: flex;
        min-height: 72vh;
        position: relative;

        .ant-form-item {
          grid-template-columns: 1fr 2.8rem;
          align-items: center;
          flex-direction: row !important;

          &-label {
            padding-bottom: 0;
          }
        }

        .company__tab-container__submit-button {
          position: absolute;
          top: 31.4rem;
        }
      }
    }
  }
}

@media (max-width: @screen-sm) {
  .company {
    form {
      .ant-form-item {
        display: grid;
      }
    }
  }
}

@media (max-width: 405px) {
  .company {
    &__tab-container {
      &__setup {
        &__logo-input--dutch {
          .ant-upload-list-picture-card-container {
            margin-right: 0.5rem;
          }

          .ant-btn-primary {
            font-size: 1rem !important;
            padding: 0 0.8rem !important;
          }
        }
      }
    }
  }
}

@media (max-width: 336px) {
  .company {
    &__tab-container {
      &__setup {
        &__logo-input {
          .ant-upload-list-picture-card-container {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
