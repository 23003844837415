.survey {
  width: 80rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .ant-collapse-item {
    border-bottom: none;
  }

  &__title {
    width: 100%;
    display: flex;
    justify-content: center;
    ::first-letter {
      text-transform: capitalize;
    }
  }
  &__accordion {
    width: 100%;
    margin-top: 2rem;
    .wifimodules {
      margin-top: 2.4rem;
    }

    .powerOptimizerWrapper {
      margin-top: 2.4rem;
    }

    .accordionArrayItem {
      width: 100%;
      border: #dee2e6 1px solid;
      padding: 2rem;
      border-radius: 12px;
      margin-top: 2.4rem;
      ::first-letter {
        text-transform: capitalize;
      }
      &__removeSurveyProduct {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
      }
    }
    .productinfo {
      background-color: #f8f9fa;
      margin-top: 3.8rem;
      border-radius: 8px;
      padding: 2.7rem;
      &__text {
        font-size: 16px;
        color: #212529;
      }

      .ant-form-item-control-input-content {
        word-break: break-all;
      }
    }

    .bottombuttons {
      display: flex;
      margin-top: 2.4rem;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3.2rem;
  }
  .firstaccordion {
    margin-top: 0;
  }
}

@media (max-width: @screen-lg) {
  .survey {
    width: 95%;
    height: 100%;
    flex-grow: 1;
  }
}
