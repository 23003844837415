.quotation-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .empty__signature__error {
    height: 2.2rem !important;
  }

  .document-library-text {
    color: rgba(0, 0, 0, 0.85);
    font-size: 1.6rem;

    &__select {
      color: #0a53d4;
      cursor: pointer;
      text-decoration: underline;
      transition: all 0.3s ease-in-out;
      width: fit-content;
      margin: 0 auto;
    }

    &__select:hover {
      scale: 1.05;
      color: #084ec8;
    }
  }
}

.document-library-modal {
  max-height: 50rem;
  overflow-y: auto;
  margin-bottom: 3rem;

  &__not-found {
    font-size: 2rem;
  }

  p {
    margin-bottom: 0;
  }

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.signature__box__error {
  border: 1px solid @main-red !important;
}

.quotation_custom__message__modal {
  .ant-modal-content {
    border-radius: 8px;
    padding: 3.6rem 5rem;

    .title {
      font-weight: 600;
      font-size: 2.4rem;
      text-align: center;
      color: black;
      margin-bottom: 4rem;
    }

    form {
      width: 100%;

      textarea {
        height: 31.2rem;
        margin-bottom: 1rem;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.5rem;
        color: #343a40;
        padding: 3rem;
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-form-item-control {
        max-width: inherit;
        width: 100%;
      }

      .send__btn {
        height: 3.6rem;
        margin-left: auto;
        display: block;
      }
    }

    .ant-modal-body {
      padding: 0;

      .modalContent {
        padding-bottom: 0;
      }
    }

    .checkbox__button__container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-checkbox-wrapper {
        .ant-checkbox-inner {
          border: 1px solid #0a53d4;
          font-size: 1.4rem;
          color: #343a40;
        }
      }
    }

    .ant-col-5 {
      flex: 0 0 40%;
      max-width: fit-content;
    }
  }
}

.quotation {
  width: 100%;
  background-color: #fff;
  position: relative;
  border-radius: 24px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  flex-direction: column;

  &__footer {
    padding-top: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ant-table-tbody {
    white-space: break-spaces;
  }
  &__subtitle {
    font-size: 1.2rem;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.7rem;

    .client__and__manager__boxes {
      display: flex;
      gap: 4rem;
      width: 100%;
    }

    .inspection_checkbox_container {
      width: 100%;
      margin-top: 6rem;
    }
  }

  .signatureImgAndDeleteWrapper {
    width: 27rem;
  }

  .signatures__buttonBox__image {
    height: 9rem;
  }

  /* QuotationForm */
  &__form {
    width: 100%;
    margin-top: 5rem;
  }
  /* QuotationClientAddress */
  .clientaddress {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;

    &__title {
      font-size: 1.4rem;
      text-transform: uppercase;
    }
    &__boxes {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
    }
    &__box {
      width: 32.8rem;
      background-color: #f8f9fa;
      border-radius: 8px;
      padding: 1.3rem 2rem;
      .title:first-child {
        margin-top: 0;
      }
      .title {
        font-size: 1.4rem;
        color: #343a40;
        margin-top: 1.5rem;
        text-transform: capitalize;
      }
      .text {
        font-size: 1.4rem;
        color: #606870;
        word-break: break-all;
      }
    }
  }

  /* QuotationActivities */
  .activities {
    width: 100%;
    margin-top: 4rem;
    &__wrapper {
      background-color: white;
    }
    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      .contentItem:first-child {
        margin-top: 0;
      }
      .contentItem {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 2.4rem;
        .contentText {
          max-width: 50rem;
          color: #868e96;
        }
        .contentNumber {
          font-size: 1.6rem;
        }
      }
    }
  }

  /* QuotationTable */
  .table {
    width: 100%;
    margin-top: 4rem;
  }

  /* QuotationTotal */
  .total {
    width: 100%;
    flex-direction: column;
    margin-top: 3rem;

    &__content {
      margin-top: 1.1rem;
    }
    &__title {
      font-size: 1.4rem;
      text-transform: uppercase;
    }
    &__totalItem:first-child {
      margin-top: 0;
    }
    &__totalItem {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #606870;
      margin-top: 1rem;
    }
    .active {
      font-weight: bold;
    }
  }

  /* QuotationAgreement */
  .agreement {
    width: 100%;
    margin-top: 5rem;
    background-color: #fbfbfc;
    padding: 3rem;
    border-radius: 12px;
    &__title {
      font-size: 1.4rem;
      color: #a2a4a6;
      font-weight: bold;
      text-transform: uppercase;
    }
    &__text {
      color: #b2b5b9;
      font-style: italic;
      margin-top: 1.5rem;
    }
    &__link {
      margin-top: 1.5rem;
    }
    &__checkbox {
      margin-top: 1rem;
    }
  }

  /* UploadDocumentQuotation */
  .uploaddoc {
    width: 100%;
    margin-top: 4rem;
    .show_additional_document {
      p {
        font-size: 1.4rem;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        margin-top: 1.5rem;
      }
      display: flex;
      align-items: center;

      gap: 1rem;
    }

    .ant-upload {
      height: 20rem;
    }
  }

  .additional__doc__error {
    &__message p,
    .anticon-paper-clip,
    .ant-upload-list-item-name {
      color: @warning-red;
    }

    &__message {
      height: 2.2rem;

      p {
        font-weight: 400;
        font-size: 1.4rem;
      }
    }
  }

  /* Installation Week */
  .installationWeek {
    width: 100%;
    margin-top: 5rem;

    &__text {
      font-style: italic;
    }
  }

  .quotationClone-language-switcher {
    position: absolute;
    top: 4rem;
    right: 4rem;
  }
  .quotation-header {
    text-transform: capitalize;
  }
}

@media (max-width: 1350px) {
  .quotation {
    .client__and__manager__boxes {
      flex-direction: column;
      grid-gap: 0;
    }
  }
}

@media (max-width: @screen-lg) {
  .quotation-section {
    margin-bottom: 0;
  }
  .quotation {
    width: 100%;
    padding-bottom: 0;
    padding: 3rem;

    &__footer {
      position: initial;
      padding-top: 3rem;
      display: flex;
      width: 100%;
    }

    .quotationClone-language-switcher {
      top: 1rem;
      right: 1rem;
    }

    .quotation-header {
      font-size: 1.8rem;
    }
  }
}

@media (max-width: @screen-sm) {
  .quotation {
    .total {
      .vat-ratio-buttons {
        display: flex;
        flex-direction: column;
      }
      &__totalItem {
        div {
          max-width: 50%;
        }
      }
    }
  }

  .quotation_custom__message__modal {
    .ant-modal-content {
      padding: 2.6rem 2rem;

      form {
        textarea {
          padding: 2rem;
        }
      }
    }
  }
}
