.general-reporting {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 8rem;

  &__title {
    font-size: 2.4rem;
    color: #212529;
    margin-top: 4.4rem;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__contentitem {
    width: 90%;
    .contentheader {
      margin-top: 3rem;
      width: 100%;
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      position: relative;

      &__title {
        font-size: 1.2rem;
        color: #212529;
        text-align: center;
      }
      &__datepicker_container {
        position: absolute;
        right: 0;
        &__datepicker {
          width: 21rem;
        }
      }
    }
  }

  &__ratioAndChartWrapper {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
  }

  &__ratioWrapper {
    width: 30%;
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 2rem;
      color: #212529;
      text-align: center;
      padding: 1rem;
      margin: 0;
      background-color: #f1f3f5;
    }
    &__body {
      background-color: #fff;
      margin: 0;
      padding: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 4rem;
      color: #212529;
    }
  }

  &__chartWrapper {
    width: 65%;
    background-color: #fff;
    display: flex;
    &__empty {
      height: 35rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .contentFooter {
    width: 100%;
    margin-top: 20px;
  }
  .ant-table-wrapper {
    width: 100%;
  }
  .ant-table-row {
    cursor: default;
  }
}

@media (max-width: @screen-lg) {
  .general-reporting {
    padding-bottom: 16rem;
    &__ratioAndChartWrapper {
      flex-direction: column;
      gap: 2rem;
    }
    &__ratioWrapper {
      width: 100%;
    }
    &__chartWrapper {
      width: 100%;
    }
  }
}

@media (max-width: @screen-sm) {
  .ant-picker-panel-container {
    max-width: 90vw;
    overflow-x: scroll;
  }
}
