/* primary button */
.ant-btn-primary {
  border-radius: 12px;
  background-color: #3d92ec;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: white;
  background-color: #1f73e2;
}

/* danger button */

.ant-btn-danger {
  border-radius: 12px;
  border: none;
  background-color: #f5222d;
  color: #fff4f3;
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #ff4d4f;

  background-color: #ffccc7;
}

/* secondary button */

.ant-btn-secondary {
  border-radius: 12px;

  border: none;
  background-color: #eff9ff;
  color: #3d92ec;
}

.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: #0a53d4;
  background-color: #d0eafe;
}

/* default button */

.defaultButton {
  border-radius: 12px;
  border-color: #0a53d4;
  color: #0a53d4;
}

/* and button danger */
.ant-btn-danger {
  background-color: #fff4f3;
  color: #f5222d;
}

.antd-switch-button {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  margin-bottom: 0;

  label::after {
    display: none;
  }

  label {
    color: #495057;
    font-size: 1.6rem;
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }
}
