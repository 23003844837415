.options {
  width: 80rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ::first-letter {
    text-transform: capitalize;
  }
  .content {
    width: 100%;
    &__header {
      display: flex;
      color: white;
      text-align: center;
      font-style: italic;
      align-items: baseline;
      margin-right: 4rem;
      .current {
        font-size: 1.6rem;
        font-weight: bold;
      }
      .whole {
        font-size: 1.3rem;
      }
    }
    .accordion {
      margin-top: 2rem;
    }
    .child_options:first-child {
      margin-top: 0;
    }
    .child_options {
      margin-top: 2rem;
      background-color: white;
      &__content {
        background-color: #eff9ff;
        border: 1px solid #1f73e2;

        .ant-collapse-header {
          color: #1f73e2;
        }
      }

      &__attached {
        background-color: #91d5ff;
      }

      &__footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 2rem;
        .icon {
          font-size: 3rem;
          cursor: pointer;
        }
        .add-icon {
          color: #52c41a;
        }
        .delete-icon {
          color: #ff7875;
        }
      }
    }
    &__title {
      font-size: 1.6rem;
      margin-top: 1.7rem;
    }
  }
}

@media (max-width: @screen-lg) {
  .options {
    width: 95%;
    height: 100%;
    flex-grow: 1;
  }
}
