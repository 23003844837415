.mainLayout {
  display: flex;
  height: 100%;
  padding-left: 8rem;

  .mainLayoutContent {
    width: 100%;
  }

  .alignButtonIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.8rem;
    ::first-letter {
      text-transform: capitalize;
    }
    svg {
      margin: 0;
      margin-right: 10px;
    }
  }
}

.alignButtonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 10px;
  }
}

/* file upload */
.fileUpload {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  .imagePreview {
    width: 13rem;
    height: 7rem;
  }
}
