.createProject__client {
  width: 80rem;
  background-color: white;
  border-radius: 24px;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }
}

@media (max-width: @screen-lg) {
  .createProject__client {
    padding-bottom: 0;
    width: 100%;
  }
}
