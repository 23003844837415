.projects-overview {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10rem;

  &__title {
    font-size: 2.4rem;
    color: #212529;
    margin-top: 4.4rem;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__contentitem {
    width: 90%;
    .contentheader {
      margin-top: 3rem;
      width: 100%;
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      position: relative;

      &__title {
        font-size: 12px;
        color: #212529;
        text-align: center;
      }
      &__datepicker_container {
        position: absolute;
        right: 0;
        &__datepicker {
          width: 21rem;
        }
      }
    }
  }
  .contentFooter {
    width: 100%;
    margin-top: 20px;
  }
  .ant-table-wrapper {
    width: 100%;
  }
  .ant-table-row {
    cursor: default;
  }
}

@media (max-width: @screen-sm) {
  .ant-picker-panel-container {
    max-width: 90vw;
    overflow-x: scroll;
  }
  .projects-overview {
    &__contentitem {
      .contentheader {
        flex-direction: column;
        align-items: flex-start;
        &__datepicker_container {
          margin-top: 1rem;
          position: initial;
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }
    }
  }
}
