.dashboard {
  min-height: calc(100vh - 8.6rem);
  background: white;
  display: grid;
  grid-template-rows: 6fr 2fr;

  &__info {
    padding-top: 5.7rem;
    padding-left: 15rem;
    display: grid;
    gap: 20rem;
    grid-template-columns: 23.7rem 1fr;
    background-color: white;

    &__hero-img {
      height: 90%;
    }

    &__user {
      padding-top: 6rem;

      &__avatar {
        height: 12.4rem;
        width: 12.4rem;
        border-radius: 100%;
        margin-bottom: 2.4rem;
      }

      &__initials {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 12.4rem;
        width: 12.4rem;
        border-radius: 100%;
        margin-bottom: 2.4rem;
        color: #1f73e2;
        font-weight: 700;
        background: #d0eafe;
        font-size: 5rem;
      }

      &__name,
      &__company-name {
        color: #343a40;
        font-weight: 600;
      }

      &__name {
        font-size: 2.2rem;
        margin-bottom: 0.8rem;
      }

      &__company-name {
        font-size: 1.8rem;
        margin-bottom: 1.95rem;
      }

      &__role-tag {
        padding: 0 1.4rem;
        padding-top: 0.2rem;
        border-radius: 4px;
        background: #cbf1be;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 2.3rem;
        color: black;
        font-size: 1.2rem;
        font-weight: 500;
        width: fit-content;
        margin-bottom: 6rem;
      }
    }

    &__pipelines {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;

      a {
        display: flex;
        padding: 1rem 2.4rem;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 1.6rem;
        font-weight: 600;
        border-radius: 4px;
        background: #1f73e2;
        min-width: 23.7rem;
      }
    }
  }

  &__menu-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: #f8f9fa;
    padding: 0 8.1rem;
    gap: 4rem;

    a {
      width: 20rem;
      height: 16rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 8px;
      transition: all 0.2s ease-in-out;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2.7rem;

      &__title {
        color: #606870;
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
  }

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 1536px) {
  .dashboard {
    grid-template-rows: 8fr 2fr;

    &__info {
      padding-top: 2rem;

      &__hero-img {
        height: 95%;
      }
    }

    &__menu-links {
      a {
        height: 12rem;
      }

      &__content {
        grid-gap: 1rem;
        gap: 1rem;
      }
    }
  }
}

@media (max-width: 1440px) {
  .dashboard {
    grid-template-rows: 1fr 24.3rem;

    &__info {
      gap: 16.2rem;
      padding-top: 5.7rem;
      padding-left: 12.6rem;

      &__hero-img {
        max-height: 53.1rem;
      }

      &__user {
        padding-top: 3.6rem;

        &__role-tag {
          margin-bottom: 5.7rem;
        }
      }
    }

    &__menu-links {
      padding: 0 8.1rem;
    }
  }
}

@media (max-width: 1280px) {
  .dashboard {
    grid-template-rows: 6fr 2fr;
    padding-top: 0;

    &__info {
      gap: 5rem;
      padding-left: 8rem;
      padding-top: 0;

      &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &__hero-img {
        max-height: 82%;
        align-self: center;
      }

      &__user {
        padding-top: 0;

        &__role-tag {
          margin-bottom: 3rem;
        }
      }

      &__pipelines {
        gap: 1.5rem;
      }
    }

    &__menu-links {
      padding: 1.5rem 8.1rem;

      &__content {
        gap: 1rem;

        svg {
          height: 4.5rem;
          width: 4.5rem;
        }
      }

      a {
        width: 15rem;
        height: 9rem;
      }
    }
  }
}

@media (max-width: 1090px) {
  .dashboard {
    &__info {
      &__hero-img {
        max-height: 65%;
      }
    }
  }
}

@media (max-width: @screen-lg) {
  .dashboard {
    grid-template-rows: min-content;

    &__info {
      gap: 3rem;
      padding: 0 3rem;
      padding-top: 4rem;

      &__hero-img {
        justify-self: end;
        max-height: 80%;
        align-self: center;
      }
    }

    &__menu-links {
      padding: 3.6rem 3rem 11rem 3rem;

      a {
        width: 18rem;
        height: 12rem;
      }

      &__content {
        grid-gap: 0;
        gap: 0;
      }
    }
  }
}

@media (max-width: 800px) {
  .dashboard {
    padding-top: 2.8rem;

    &__info {
      grid-template-columns: 1fr;
      padding: 0 1.8rem;
      padding-bottom: 4.4rem;

      &__hero-img {
        display: none;
      }

      &__user {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__initials {
          margin: 0 auto;
          height: 10.8rem;
          width: 10.8rem;
          margin-bottom: 0.9rem;
        }

        &__name {
          font-size: 2rem;
          margin-bottom: 0.4rem;
        }

        &__company-name {
          font-size: 1.4rem;
          margin-bottom: 2.35rem;
        }

        &__role-tag {
          margin: 0 auto;
          margin-bottom: 6.7rem;
        }

        p {
          text-align: center;
        }

        &__pipelines {
          gap: 1.8rem;

          a {
            font-size: 1.6rem;
          }
        }
      }
    }

    &__menu-links {
      padding: 3.6rem 1.8rem 11rem 1.8rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1.8rem;
      row-gap: 2.8rem;
      align-items: start;

      a {
        width: 100%;
        height: 9.2rem;
      }

      &__content {
        gap: 0.4rem;

        svg {
          width: 3.8rem;
          height: 3.73rem;
        }
      }
    }
  }
}

@media (max-width: @screen-sm) {
  .dashboard {
    &__menu-links {
      justify-content: space-between;
    }
  }
}
