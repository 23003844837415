.mainScreenContact {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .contactTableBox {
    width: 90%;
    position: relative;
  }
  .tableControlButtons {
    width: 90%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 1.5rem;
    button:first-child {
      margin-right: 1rem;
    }
  }
}

/* empty table styles */
.introScreenContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;
  p {
    margin-top: 2rem;
    color: #868e96;
    font-size: 20px;
  }
  img {
    width: 12.3rem;
    height: 10.6rem;
  }
  button {
    margin-top: 5rem;
  }
}

.modalContent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  padding-bottom: 3rem;
  .wizardPosition {
    position: absolute;
    right: 0;
    top: 0.5rem;
    font-size: 16px;
    color: #adb5bd;
    right: 5rem;

    span {
      font-size: 20px;
      color: #868e96;
    }
  }

  &__button {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-right: 5rem;
    width: 100%;
    justify-content: flex-end;
    gap: 1.8rem;
    display: flex;
    &__child {
      ::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .formContent {
    margin-top: 3.2rem;
    width: 100%;
    padding-left: 3rem;
    padding-right: 5rem;

    .fusebox_input {
      .ant-form-item-label {
        white-space: initial;
        text-align: initial;

        ::after {
          content: '';
        }
      }
    }
  }

  &__header {
    min-width: 100%;
    height: @header-height;
    display: none;
    align-items: center;
    padding-left: 3.2rem;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px #dee2e6;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    &__icon {
      cursor: pointer;
    }
  }

  .terms_title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.8rem;
    color: #343a40;
  }

  .terms_body {
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #868e96;
    padding: 3.5rem 10rem;
    max-height: 60rem;
    overflow-x: auto;
  }
}

.custom_dropdown {
  display: flex;
  flex-wrap: nowrap;
  padding: 0.8rem;
  &__input {
    flex: auto;
  }
  &__button {
    height: 3.5rem;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.8rem;
    cursor: pointer;
  }

  .sm-input-with-error {
    height: 3.2rem;
    min-height: 3.2rem;
  }
}

@media (max-width: @screen-lg) {
  .modalContent {
    padding-top: calc(4.2rem + @header-height);
    .add_inspection_button {
      padding-left: 0;
      padding-right: 0;
    }
    &__button {
      padding-top: 4.2rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0px 48px calc(@footer-height + 34px);
      width: 100%;
      flex-grow: 1;
      position: initial;
    }

    &__header {
      display: flex;
    }

    .terms_body {
      padding: 3.5rem 5rem calc(@footer-height + 34px);
    }

    min-height: 100vh;
    height: auto;
    align-items: center;
    justify-content: initial;
    padding-bottom: 0;
  }

  .mainScreenContact {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    flex-grow: 1;
    .contactTableBox {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}

@media (max-width: @screen-sm) {
  .modalContent {
    max-width: 100vw;

    .project_modal {
      width: 100%;
    }

    &__button {
      width: 90%;
      padding: 4.2rem 0 calc(@footer-height-mobile + 3rem);
      &__child {
        width: 100%;
      }
    }

    &__header {
      height: 6.8rem;
      &__icon {
        margin-top: 3rem;
        margin-bottom: 2rem;
      }
    }

    .formContent {
      width: 90vw;
      padding: 0;
    }
  }
}
