.homescreen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  min-height: 100vh;

  .header {
    .back-link {
      display: none;
    }
  }

  &__project-creation--mobile {
    display: none;
  }

  &__contents {
    width: calc(100% - 7.6rem);
    display: flex;
    flex-direction: column;
    padding-bottom: 10rem;

    &__table__switcher {
      display: flex;
      justify-content: space-between;

      .leads__link {
        position: relative;

        &__container {
          display: flex;
          justify-content: center;
          width: 100%;
          position: relative;
        }

        .leads__count {
          background-color: #ff4d4f;
          width: 2.4rem;
          height: 2.4rem;
          color: white;
          border-radius: 50%;
          position: absolute;
          font-size: 1.3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
        }
      }
    }

    .leads__button {
      font-size: 1.4rem;
      color: #3d92ec;
      border: 1px solid #3d92ec;
      border-radius: 6px;
      background-color: transparent;
    }

    &__categories {
      width: 33.3rem;
      display: flex;
      justify-content: space-between;

      .active__category {
        border-bottom: 3px solid #1f73e2 !important;
        color: #252e3b !important;
      }

      .project__category {
        cursor: pointer;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        gap: 0.8rem;
        font-size: 1.4rem;
        font-weight: 600;
        color: #808a98;
        border-bottom: 3px solid #cfd4da;
        padding-bottom: 1.3rem;

        p {
          margin-bottom: 0;
        }

        span {
          color: #606870;
          font-weight: 400;
          font-size: 1.4rem;
          background-color: #e9ecef;
          border-radius: 8px;
          padding: 0 0.4rem;
        }

        .highlighted__number {
          color: #1d39c4;
          background-color: #f0f5ff;
        }
      }
    }
  }

  .filters__and__sorts__container {
    width: calc(100% - 7.6rem);
    margin: 2.4rem 0;
    margin-top: 0;
    padding: 2.4rem 0;

    .filters {
      gap: 0.8rem;
      display: flex;
    }

    .tags__container {
      margin-top: 2.9rem;
    }

    .status__managers__group {
      display: flex;
      gap: 0.8rem;
    }

    .search__input__container {
      width: 33.3rem;

      .searchInput {
        border: 1px solid #adb5bd;
        background-color: transparent;
        border-radius: 6px;
      }

      .searchButton {
        border-radius: 0px 6px 6px 0px;
        width: 4.4rem;

        svg {
          font-size: 2.4rem;
        }
      }
    }

    .projects__filter__dropdown__active {
      background-color: white;
    }

    .projects__filter__dropdown {
      border-radius: 6px;
      width: 18rem;
      height: 3.8rem;
      border: 1px solid #adb5bd;
      position: relative;

      .filters__overlay {
        z-index: 9;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .projects__filters__checkbox {
        z-index: 99;
        position: absolute;
        left: 0;
        top: 4.2rem;
        background-color: white;
        border-radius: 12px;
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.06));
        padding: 1.6rem;

        .ant-form-item {
          margin-bottom: 0;
        }

        .total__projects__count {
          font-weight: 600;
          font-size: 1.2rem;
          color: #495057;
        }

        .status__checkbox__group {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 24rem;
          width: 58rem;
          border-top: 1px solid #f1f3f5;
          border-bottom: 1px solid #f1f3f5;
          margin-bottom: 1.2rem;

          label {
            height: 4.6rem;
            align-items: center;
            font-weight: 400;
            font-size: 1.4rem;
            color: #495057;

            span div {
              padding-top: 0.6rem;
            }
          }
        }

        .manager__checkbox__group {
          width: 29rem;
          max-height: 25rem;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          gap: 2.4rem;
          border-top: 1px solid #f1f3f5;
          border-bottom: 1px solid #f1f3f5;
          padding: 1.5rem 0;

          label {
            align-items: flex-start;
            font-size: 1.4rem;
            color: #343a40;

            span div {
              padding-top: 0;
              word-break: break-all;
            }
          }
        }
      }

      &__container {
        padding: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .label__title {
          display: flex;
          align-items: center;
          gap: 0.8rem;
        }
      }
    }
  }

  ::placeholder {
    color: #adb5bd;
    font-weight: 400;
    font-size: 1.2rem;
    opacity: 1;
  }

  .ant-checkbox-inner {
    background-color: #f8f9fa;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #3d92ec !important;
    border-color: #3d92ec !important;
  }

  .ant-avatar-group {
    .attachment {
      cursor: pointer !important;
    }
  }

  .margin-t {
    margin-top: 1.2rem;
  }

  .action__button__group__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .reset__button {
      color: #868e96;
    }

    .cancel__button {
      color: #3d92ec;
      padding: 1rem;
    }

    .save__button {
      color: white;
      border-radius: 12px;
      background-color: #3d92ec;
      padding: 0.4rem 1rem;
    }

    button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      font-weight: 600;
      font-size: 1.4rem;
    }
  }

  .content {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
      font-size: 2.4rem;
      color: #212529;
    }
    &__createproject {
      position: absolute;
      right: 0;
      top: 0;
    }
    &__table {
      width: 100%;
      margin-top: 1.6rem;

      .ant-table-container {
        background-color: transparent;
      }

      .ant-table-content {
        border-radius: 12px;
      }

      .ant-table.ant-table-bordered > .ant-table-container {
        border-left: none !important;
      }

      .ant-table {
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.06));
        border-radius: 15px !important;
      }

      .ant-table-thead {
        .ant-table-cell {
          font-size: 1.4rem;
          color: #212529;
          font-weight: 600;
        }
      }

      .ant-table-cell {
        border-right: 1px solid #dee2e6 !important;
        border-bottom: 1px solid #f8f9fa !important;
      }

      .ant-table-cell:last-of-type {
        border-right: none !important;
      }

      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        color: #343a40;
      }

      .ant-table-column-sorter-up.active,
      .ant-table-column-sorter-down.active {
        color: #7f8082;
      }
    }

    &__search {
      right: 0;
      top: 0;
      position: absolute;
    }
  }
  .content:last-child {
    margin-bottom: 10rem;
  }

  /* homescreen searchProjects component */
  &__manager__container {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    .icon__container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.2rem;
      width: 3.2rem;
      border-radius: 100px;
      background-color: #eff9ff !important;
      color: #1f73e2;
      font-size: 2rem;
      font-weight: 600;
    }
    p {
      margin-bottom: 0 !important;
      font-size: 1.4rem;
    }
  }
  &__status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &--single {
      justify-content: flex-end;
    }
    &-tag {
      padding: 2px 10px;
      background-color: #f8f9fa;
      font-size: 14px;
      color: rgba(255, 77, 79, 1);
      display: inline;
      border-radius: 4px;
      white-space: nowrap;
      &.archived {
        color: #7d7d7d;
      }
    }
  }
  &__type-icon {
    font-size: 20px;
    line-height: 20px;
    color: rgba(173, 181, 189, 1);
    padding-bottom: 0.2rem;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table {
    border-top: none;
  }

  &__actions {
    width: 28px;
    background-color: #f8f9fa;
    height: 28px;
    border-radius: 100%;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-left: 15px;
    border: 1px solid rgba(239, 249, 255, 1);
    &:focus {
      border: 1px solid rgba(239, 249, 255, 1);
      background: rgba(239, 249, 255, 1);
    }
    &-icon {
      font-size: 18px;
      color: #606870;
    }
  }
  &__attachment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background: rgba(239, 249, 255, 1);

      img {
        height: 3rem;
        width: 3rem;
      }
    }
    &__container {
      display: flex;
      gap: 1.5rem;
      align-items: center;
    }
  }

  &__header {
    padding: 0 3.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      text-transform: capitalize;
      font-weight: 600;
      font-size: 2.4rem;
      color: #212529;
      margin-bottom: 0;
    }

    &__image {
      position: absolute;
      top: 5rem;
      left: 50%;
      transform: translateX(-50%);
      max-width: 30rem;
      height: 6rem;
    }

    .new__project__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 600;
      font-size: 1.6rem;
      padding: 1.2rem 2.4rem 1.2rem 2rem;
      height: 4.8rem;
      border-radius: 12px;
      gap: 1.3rem;
    }
  }

  .manager__search__input__container {
    border-top: 1px solid #f1f3f5;
    position: relative;

    .search__icon {
      position: absolute;
      top: 1.8rem;
      right: 1.2rem;
    }

    .manager__search__input {
      border: 1px solid #dee2e6;
      padding: 0.8rem 1.2rem;
      padding-right: 3.2rem;
      border-radius: 6px;
      font-size: 1.2rem;
      margin: 1.2rem 0;
      color: #343a40;
      height: 3.2rem;
      outline: none;
      width: 100%;
    }
  }
}

.filter__dropdown__icon,
.filter__dropdown__icon__active {
  color: #adb5bd;
}

.filter__dropdown__icon {
  transform: rotate(180deg);
}

.filter__dropdown__icon__active {
  transform: rotate(0deg);
}

.type__container {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  .briefcase__icon {
    padding-top: 0.4rem;
  }
}

.view__all__tags__container {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  gap: 1.2rem;
}

.all__tags__modal__clear__btn {
  font-size: 1.8rem;
  font-weight: 600;
  color: #1f73e2;
  margin-top: 2rem;
}

.project__attachments__container {
  border-bottom: 1px solid #f1f3f5;
  height: 4.6rem;
  color: #212529;
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 1.6rem;
  padding-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .attachment {
    background-color: white;
    border: 1px solid #e9ecef;
  }

  .title {
    margin-bottom: 0;
  }
}

.desktop__project__attachments__container {
  margin-top: 0;
  padding-bottom: 0;
  height: fit-content;
  border-bottom: none;
}

.desktop__table__visibility {
  display: block;
}

.tablet__table__visibility {
  display: none !important;
}

.archive-delete-box-menu {
  border: 1px solid rgba(222, 226, 230, 1);
  border-radius: 15px;
  background: rgba(248, 249, 250, 1);
  padding: 0;

  &__item {
    min-width: 15.6rem;
    background: none;

    &__delete {
      color: rgba(255, 77, 79, 1);
      border-top: 1px solid rgba(222, 226, 230, 1);
    }
  }
}

.tags__container {
  display: grid;
  grid-template-columns: 10fr 1fr;

  .clear__all__tags {
    font-size: 1.4rem;
    font-weight: 600;
    color: #1f73e2;
    width: 8.2rem;
    text-align: right;
    cursor: pointer;
    justify-self: end;
  }

  .tags__list__mobile {
    display: none !important;
  }

  .tags__list {
    display: flex;
    gap: 0.8rem;
    flex-wrap: wrap;
  }
}

.status__tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 1rem;
  gap: 1rem;
  background-color: white;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  height: 2.6rem;

  &__name {
    font-weight: 600;
    font-size: 1.4rem;
    color: #495057;
    margin-bottom: 0;
  }

  svg {
    cursor: pointer;
  }
}

.justify-end {
  justify-content: flex-end !important;
}

.project__count__details__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 2.8rem;

  .project__count__details {
    text-align: left;
    color: #606870;
    font-weight: 400;
    font-size: 1.2rem;
    height: 1.8rem;
    margin-bottom: 0;
  }

  .view_mode_icons {
    display: none;
  }
}

.ticket__view {
  display: none;
}

.tickets__list {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.7rem;
  column-gap: 0.8rem;
  justify-content: space-between !important;
  list-style: none;

  &__item {
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    width: 100%;
    height: fit-content;
    border: 1px solid #f1f3f5;
    padding: 1.8rem 1.6rem 0.8rem 1.6rem;

    &__header {
      display: flex;
      justify-content: space-between;

      .project_id {
        color: #212529;
        font-weight: 600;
        font-size: 1.4rem;
        text-transform: capitalize;
      }

      .project_status {
        background-color: #fff2e8;
        padding: 0.2rem 1rem;
        border-radius: 4px;
        font-weight: 400;
        font-size: 1.4rem;
        color: #ff4d4f;
      }

      .project_archived {
        background-color: #f1f1f1 !important;
        color: #7d7d7d !important;
      }

      border-bottom: 1px solid #f1f3f5;
      padding-bottom: 1.6rem;
    }

    .info__container {
      margin-top: 1.6rem;
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .title {
        color: #212529;
        font-weight: 600;
        font-size: 1.4rem;
      }

      .value {
        font-weight: 400;
        font-size: 1.4rem;
        color: #343a40;
        text-align: right;
      }
    }

    &__footer {
      padding-top: 0.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.8rem;
      border-top: 1px solid #f1f3f5;

      span {
        color: #495057;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      button {
        background-color: #f8f9fa;
        margin-left: 0;

        svg {
          color: #606870;
        }
      }
    }
  }
}

@media (max-width: 1160px) {
  .homescreen {
    .statuses__checkbox {
      transform: translateX(-33%);
    }
  }
}

@media (max-width: 1105px) {
  .homescreen {
    .managers__checkbox {
      transform: translateX(-24%);
    }
  }
}

@media (max-width: 850px) {
  .homescreen {
    .statuses__checkbox {
      transform: translateX(-37%);
    }

    .managers__checkbox {
      transform: translateX(-47%);
    }
  }
}

@media (max-width: 815px) {
  .homescreen {
    .status__checkbox__group {
      width: 48rem !important;
    }
  }
}

@media (max-width: @screen-lg) {
  .homescreen {
    .content {
      align-items: initial;

      &__title {
        text-align: center;
      }
      &__search {
        position: relative;
        margin-top: 1.9rem;
        width: 100%;
      }
      &__createproject {
        position: relative;
        margin-top: 1.9rem;
        display: flex;
        justify-content: flex-end;
      }
    }
    &__manager__container {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      .icon__container {
        height: 3rem;
        width: 3rem;
        font-size: 1.6rem;
      }
    }

    &__header {
      &__title {
        font-size: 2rem;
      }
    }
  }

  .ticket__view {
    display: block;
    margin-top: 2rem;
  }

  .project__count__details__container {
    .view_mode_icons {
      display: flex;
      align-items: center;
      gap: 1.6rem;
    }
  }

  .desktop__table__visibility {
    display: none !important;
  }

  .tablet__table__visibility {
    display: block !important;
  }
}

.tablet__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4.2rem;

  .ant-pagination-item,
  .ant-pagination-item-active,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    background-color: transparent !important;
    border: none;
  }
}

.mobile__pagination {
  display: none;
}

@media (max-width: @screen-sm) {
  .homescreen {
    &__header {
      &__title {
        display: none;
      }

      .new__project__btn {
        display: none;
      }
    }

    &__project-creation--mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-top: 2rem;
      padding-bottom: 1.6rem;
      border-bottom: 1px solid #dee2e6;
      width: 90%;

      p {
        margin-bottom: 0;
        text-transform: capitalize;
        color: #212529;
        font-size: 2rem;
        font-weight: 600;
      }

      .new__project__btn {
        height: 4rem;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        padding: 0 2.4rem;

        svg {
          height: 2.4rem;
          width: 2.4rem;
        }

        span {
          color: white;
          font-size: 1.6rem;
          font-style: italic;
          font-weight: 300;
        }
      }
    }

    &__contents {
      width: 100%;

      &__table__switcher {
        flex-direction: column;

        .leads__link {
          display: flex;
          justify-content: center;
          width: 90%;
          margin: 0 auto;
          margin-top: 1.5rem;

          .leads__button {
            width: 100%;
            height: 3.8rem;
          }
        }
      }

      &__categories {
        border-top: 1px solid #dee2e6;
        margin: 0 auto;
        width: 90%;
        padding-top: 1.6rem;
      }

      .ant-table-pagination.ant-pagination {
        display: none;
      }
    }

    .filters__and__sorts__container {
      width: 90%;
      padding: 1.6rem 0;
      margin-bottom: 0;

      .projects__filter__dropdown__container {
        width: 100%;
      }

      .projects__filter__dropdown {
        width: 50%;
        height: auto;
        display: flex;
        align-items: center;
      }

      .dropdown__full__width {
        width: 100%;
      }

      .search__input__container {
        width: 100%;

        .searchInput {
          border: 1px solid #3d92ec !important;
        }
      }

      .filters {
        flex-direction: column;
      }
    }

    .content {
      &__createproject {
        justify-content: initial;
      }
      &__table {
        margin-top: 2rem;
      }
    }

    .content:last-child {
      margin-top: 5.4rem;
    }

    &__header-wrapper {
      height: 8.6rem;
    }

    &__header {
      height: 8.6rem;
      padding: 0;

      .logo {
        top: 30%;
      }

      .back-link {
        display: block !important;
      }

      &__title {
        margin-top: 5rem;
      }

      .new__project__btn {
        height: 4rem;
        border-radius: 8px;
        font-size: 1.5rem;
        padding: 0.4rem 0.8rem;
        margin-top: 5rem;
      }
    }

    .action__button__group__container {
      justify-content: end;

      .reset__button {
        display: none;
      }
    }
  }

  .tags__container {
    margin-top: 0 !important;
    grid-template-columns: 1fr;

    .tags__list__mobile {
      display: flex !important;
      margin-top: 2.4rem;
      justify-content: space-between;

      .tags {
        display: flex;
        gap: 0.8rem;
        flex-wrap: wrap;
      }
    }

    .clear__all__tags,
    .tags__list {
      display: none;
    }
  }

  .tags__settings__container {
    width: 19.3rem;
    height: 9.9rem;

    li {
      color: #495057;
      font-weight: 400;
      font-size: 1.4rem;
    }

    li:first-child {
      border-bottom: 1px solid #f1f3f5;
    }
  }

  .tickets__list {
    grid-template-columns: 1fr;
  }

  .project__count__details__container {
    justify-content: flex-end;
    margin-top: 2.8rem;
    width: 95%;

    .project__count__details {
      display: none;
    }
  }

  .tablet__pagination {
    display: none;
  }

  .mobile__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.9rem;

    .ant-pagination-prev,
    .ant-pagination-next {
      background-color: #d0eafe;
      border-radius: 8px;
      height: 3.2rem;
      width: 3.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-pagination-prev {
      .ant-pagination-item-link {
        background-color: transparent;
      }
    }

    .ant-pagination-simple-pager {
      input {
        background-color: transparent !important;
        width: 1.5rem;
        border: none;
        padding: 0;
        margin: 0;
      }

      .ant-pagination-slash {
        margin: 0;
      }
    }
  }

  .statuses__checkbox {
    transform: translateX(0) !important;

    .status__checkbox__group {
      width: 82vw !important;
      display: grid !important;
      grid-template-columns: 1fr 1fr !important;
      height: 30rem !important;
    }
  }

  .filters__and__sorts__container {
    .filters {
      gap: 1rem !important;
    }
  }

  .managers__checkbox {
    transform: translateX(-47vw) !important;

    .manager__checkbox__group {
      width: 84vw !important;
      max-height: 30rem !important;
    }
  }
}

@media (max-width: @screen-xs) {
  .homescreen {
    &__contents {
      &__categories {
        .project__category {
          gap: 0.5rem;

          span,
          p {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}

@media (max-width: 395px) {
  .homescreen {
    .managers-filter {
      .label__title {
        gap: 0.4rem !important;

        div {
          font-size: 1.2rem;
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .homescreen {
    .managers-filter {
      .label__title {
        div {
          font-size: 1rem;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .label__title {
    .managers__label {
      padding-top: 0rem;
    }
  }
}
