@media (max-width: @screen-lg) {
  .customModal {
    z-index: 1;
    .ant-modal-close-x {
      display: none;
    }
    .ant-modal {
      margin: unset;
      padding: 0;
      min-width: 100%;
    }
    .ant-modal-body {
      margin: 0;
      padding: 0;
    }
  }
}
