.controle {
  width: 100%;
  min-height: 20rem;

  .title {
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;

    .contentbox:first-child {
      border: none;
      padding: none;
    }
    .contentbox {
      border-left: #dee2e6 1px solid;
      padding-left: 2.8rem;

      &__icon {
        width: 1.6rem;
        height: 1.6rem;

        svg {
          color: #1f73e2;
          font-size: 1.6rem;
        }
      }

      .text {
        word-wrap: break-word;
        font-size: 1.6rem;
        max-width: 15rem;
      }
    }
  }
}

@media (max-width: @screen-lg) {
  .controle {
    &__content {
      flex-direction: column;
      gap: 2rem;

      .contentbox {
        border: none;
        padding-left: 0;
        display: flex;
        align-items: center;
        gap: 1rem;

        .text {
          max-width: 100%;
        }
      }
    }
  }
}
