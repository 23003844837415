.planning-tool-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

.weekdays-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 70%;

  .chose-working-hours {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .day-and-time {
    display: flex;
    align-items: center;
    gap: 2rem;

    .weekday-name {
      min-width: 10rem;
      max-width: 40%;
      display: flex;
      align-items: center;
    }
  }
}
