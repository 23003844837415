.leads {
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 0 7.6rem;

  &__page-title--mobile {
    display: none;
  }

  &__header {
    width: 100%;
    padding-bottom: 0;
    padding-left: 7.6rem;

    .back-link {
      display: none;
    }
  }

  &__page__title {
    font-weight: 600;
    color: #212529;
    font-size: 2.4rem;
    display: flex;
    align-items: center;
    margin: 0;
  }

  .search__delete__wrapper {
    display: flex;
    justify-content: end;
    margin-bottom: 2.8rem;
    margin-top: 5.4rem;
    gap: 1.5rem;

    .ant-btn-danger {
      font-size: 1.6rem;
      text-shadow: none;
      box-shadow: none;
    }

    .searchInput {
      background-color: transparent;
    }

    input::placeholder {
      color: #adb5bd;
      opacity: 1;
    }
  }

  .custom-antd-table {
    .client__name {
      color: #1f73e2;
    }

    .ant-table-row {
      td:nth-child(2) {
        width: 12rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .leads {
    padding-left: 3rem;
    padding-right: 3rem;

    &__header {
      padding-left: 3rem;
    }
  }
}

@media (max-width: @screen-lg) {
  .leads {
    padding-bottom: 10rem;
  }
}

@media (max-width: @screen-sm) {
  .leads {
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    &__page-title--mobile {
      display: block;
      color: #212529;
      font-size: 2rem;
      font-weight: 600;
      margin-top: 3rem;
      text-align: center;
    }

    &__page__title {
      display: none;
    }

    &__header {
      height: 8.6rem;
      padding-left: 0;

      .back-link {
        display: block;
      }

      .logo {
        top: 30%;
      }
    }

    &__header-wrapper {
      height: 8.6rem;

      ::before,
      ::after {
        height: 8.6rem;
      }
    }

    &__page__title {
      margin-top: 5.4rem;
    }

    .search__delete__wrapper {
      margin-top: 3rem;
      flex-direction: column;

      .searchForm {
        width: 100%;
      }
    }
  }
}
